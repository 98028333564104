import { Container, Col } from "react-bootstrap";
import "./style.css";

const PrivacyPolicy = () => {
  return (
    <>
      <Container fluid className="tnc_top  background-grey  top_class">
        <Col lg="12" className="privacyhead">
          <p>Privacy Policy</p>
        </Col>
        <Container>
          <Col lg="12" className="privacy-details">
            <Col lg="11">
              <div className="priv_sub">Welcome to Cars2Cars! Our Privacy Policy ("Policy") is designed to outline how we gather, utilize, reveal, and safeguard your personal information when you engage with the Cars2Cars website and online platform ("Platform") for the purpose of purchasing and selling vehicles via a bidding process. By accessing or utilizing the Platform, you consent to the practices elucidated in this Policy.</div>
              <ol>
                <li className="priv_sub11">
                  <span>
                  Information We Collect
                  </span>
                  <ol>
                    <li>We gather data you provide during the registration of your account, the placement of bids, or the listing of vehicles. This data can include your name, email address, phone number, and other contact information. </li>
                    <li>Listing creation involves sellers supplying vehicle details, specifications, images, and other relevant information. </li>
                    <li>Listing creation involves sellers supplying vehicle details, specifications, images, and other relevant information. </li>
                    <li>We collect information regarding your interactions with the Platform, which includes details about your browsing activity, IP address, and device information.</li>

                  </ol>
                </li>
                <li className="priv_sub11">
                  <span>
                  How we use your Information:
                  </span>
                  <ol>
                    <li>We utilize your information to process bids, facilitate vehicle transactions, and deliver associated services. </li>
                    <li>Your contact information can be utilized to communicate transaction notifications, updates, and promotional content.</li>
                    <li>Your feedback and usage data play a vital role in enhancing the functionality, user experience, and features of the Platform.</li>
                    <li>We may employ and disclose your information as necessitated by law or in response to legal requests.</li>

                  </ol>
                </li>
                <li className="priv_sub11">
                  <span>
                  How we protect your information
                  </span>
                  <ol>
                    <li>We have implemented security measures to safeguard your personal and transactional information against unauthorized access, disclosure, or alteration.</li>
                    <li>Your data is secured through encryption technology when it is transmitted.</li>
                    <li>Only authorized personnel have access to your information, and they are obligated to uphold its confidentiality.</li>
                  </ol>
                </li>
                <li className="priv_sub11">
                  <span>
                  Sharing your information
                  </span>
                  <ol>
                    <li>To facilitate transactions, certain information, including contact details and transaction data, may be shared between buyers and sellers.</li>
                    <li>Third-party service providers may be involved in helping us with various aspects of our services, including payment processing and analytics.</li>
                    <li>We may be obligated to disclose information in response to legal mandates, legal procedures, or government inquiries.</li>
                  </ol>
                </li>
                <li className="priv_sub11">
                  <span>
                  Your Choices
                  </span>
                  <ol>
                    <li>You can easily make changes or updates to your account information within your profile settings.</li>
                    <li>Unsubscribing from promotional emails is easy – just follow the steps detailed in the emails.</li>
                  </ol>
                </li>
                <li className="priv_sub11">
                  <span>
                  Cookies & Tracking
                  </span>
                  <ol>
                    <li> We utilize cookies and similar tracking technologies to improve your browsing experience and gather data on how our platform is used.</li>
                    <li> It's important to be aware that specific third-party services integrated into our Platform may utilize cookies. To learn more, we suggest reviewing their individual privacy policies.</li>
                  </ol>
                </li>
                <li className="priv_sub11">
                  <span>
                  Changes to the policy
                  </span>
                  <ol>
                    <li> We may revise this Policy to align with alterations in our practices or legal obligations. In the event of significant changes, we will notify you accordingly.</li>
                    <li> For any inquiries, concerns, or requests regarding your personal information or this Policy, please do not hesitate to contact us.</li>
                  </ol>
                </li>
              </ol>
              <span className="can_policy">Cars2Cars Online Platform – Cancellation Policy </span>
              <ol>
                <li className="priv_sub11">
                  <span>Cancellation of Bids:</span>
                  <ol>
                  <li>Bids made on the Platform are regarded as definitive and legally binding. Once a bid is submitted, it cannot be cancelled or withdrawn.</li>
                  </ol>
                </li>
                <li className="priv_sub11">
                  <span>Cancellations of Transactions:</span>
                  <ol>
                  <li> Buyer's Right to Cancel: Upon winning an auction, buyers are granted a restricted time frame within which they can cancel the transaction. The precise cancellation period will be communicated during the transaction confirmation process.</li>
                 <li>Seller's Right to Cancel: Sellers retain the right to cancel a transaction under exceptional circumstances, such as the unavailability of the vehicle or inaccuracies in the listing. Sellers are obliged to promptly communicate the cancellation and furnish a valid reason.</li>
                </ol>
                 
                </li>
                <li className="priv_sub11">
                  <span>Refund Policy:</span>
                  <ol>
                  <li>In situations where a buyer's cancellation falls within the stipulated cancellation period, a refund of the winning bid amount may be processed. The refund procedure will commence within 15 business days upon receipt of the cancellation request.</li>
                  <li>Refund for seller confirmations: In the case of seller-initiated cancellations due to unavailability or inaccuracies, any funds received from the buyer will be promptly refunded.</li>
                  <li>No Refunds for Completed Transactions: Once a transaction has been finalized and payment has been made, refunds will not be granted, unless there is non-compliance with the listing details by the seller.</li>                  
                  </ol>
                </li>
                <li className="priv_sub11">
                  <span>Communication and Notification:</span>
                  <ol>
                  <li>For cancellation requests, buyers or sellers are required to notify Cars2Cars through the specified channels available on the Platform.</li>
                  <li>Notification to Users: In the event of a cancellation and the subsequent refund procedures, Cars2Cars will promptly inform both buyers and sellers using the contact information provided during the transaction.</li>
                  </ol>
                </li>
                <li className="priv_sub11">
                  <span>Non-Compliance and Disputes:</span>
                  <ol>
                  <li>If a cancellation is sought as a result of a dispute between the buyer and seller, Cars2Cars may assist in facilitating communication and negotiation between the parties to facilitate an amicable resolution.</li>
                  </ol>
                </li>
                <li className="priv_sub11">
                  <span>Changes to this Policy:</span>
                  <ol>
                  <li> Cars2Cars retains the right to amend or revise this Cancellation Policy at any given time. Such changes will become effective upon being posted on the Platform.</li>
                  </ol>
                </li>
                <li className="priv_sub11">
                  <span>Contact Us:</span>
                  <ol>
                  <li>We are available to assist you with any questions, concerns, or requests regarding cancellations and refunds. Contact us for assistance.</li>
                  </ol>
                </li>

              </ol>
              <div className="priv_sub">By utilizing the Cars2Cars Platform, you acknowledge that you have read, comprehended, and consented to abide by this Cancellation Policy. If you do not agree with this Policy, we kindly request that you refrain from using our services.</div>
            </Col>
          </Col>
        </Container>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
