import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Row, Col, Card, Container } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import "./blog.css";
import { blogData, FaqData, mainblogData } from "./blogdata";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ApiGetMethod } from "./js/helper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import constant from "./js/constant";

const Blog = () => {
  const navigate = useNavigate();

  const navigateToBlogDescription = (blogId) => {
    window.scrollTo(0, 0);
    window.location.href = `/#/singleBlogDescription?blogId=${blogId}`;
  };

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    let url = `${constant.baseurl}viewAllBlogs`;
    ApiGetMethod(url).then((response) => {
      if (response.error == false) {
        setBlogs(response.Blogs);
      } else {
      }
    });
  }, []);

  return (
    <>
      <Container fluid className="top_class pt-1">
        <Col
          lg="12"
          className="blog_heading p-2 justify-content-center d-flex align-items-center"
        >
          <h2 className=" m-0">BLOGS</h2>
        </Col>

        <div>
          <Col className="blog_wrapper">
            {blogs.map((blogs, i) => (
              <div>
                <Card
                  className="rounded-4 blog_card11"
                  onClick={() => navigateToBlogDescription(blogs._id)}
                  key={i}
                >
                  <Card.Body
                    style={{
                      display: " flex",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                      padding: "0",
                      borderRadius: "30px 30px 0 0 ",
                      height: "170px",
                    }}
                  >
                    <Card.Img
                      src={blogs.blogImage}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "30px 30px 0 0 ",
                      }}
                    />
                  </Card.Body>

                  <Card.Body
                    className="border border-bottom-2"
                    style={{ height: "115px" }}
                  >
                    <Card.Title style={{ fontSize: " 18px" }}>
                      {blogs.title
                        ? blogs.title.length > 25
                          ? `${blogs.title.substring(0, 25)}...`
                          : blogs.title
                        : ""}
                    </Card.Title>
                    <Card.Text
                      style={{
                        fontFamily: "poppins",
                        textAlign: "center",
                        fontSize: "  14px",
                      }}
                    >
                      {blogs.description
                        ? blogs.description.length > 97
                          ? `${blogs.description.substring(0, 97)}...`
                          : blogs.description
                        : ""}
                    </Card.Text>
                  </Card.Body>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    <Card.Title
                      style={{
                        paddingLeft: " 1rem",
                        fontSize: "1rem",
                        color: "#7F7F7F",
                      }}
                    >
                      {`${new Date(blogs.updatedAt).toLocaleDateString(
                        "en-US",
                        {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        }
                      )} `}
                    </Card.Title>
                    <FaArrowRight
                      onClick={() =>
                        navigate("/singleBlogDescription?blogId=" + blogs._id)
                      }
                      color=" rgb(127, 127, 127)"
                    />
                  </div>
                </Card>
              </div>
            ))}
          </Col>
        </div>
      </Container>
    </>
  );
};

export default Blog;
