// const constant = {
//        baseurl: `http://localhost:5000/api/`,
//        // baseurl: `http://43.207.157.131:5000/api/`,

//       shareUrl: `http://localhost:3000/register`,

//        timezone : 'Asia/Kolkata'
// }

const constant = {
  //   baseurl: `http:// http://43.207.157.131:5000/:5000/api/`,
  baseurl: `https://app.cars2cars.in/api/`,
  imgurl: `https://app.cars2cars.in/dist/img`,
  //  baseurl: `http://localhost:5000/api/`,
  //  imgurl: `http://localhost:5000/dist/img`,

  // shareUrl: `http://localhost:3000`,
  shareUrl: `https://cars2cars.in`,

  timezone: "Asia/Kolkata",
};

export default constant;

export const colorOptions = [
  { name: "white", value: "#FFFFFF" },
  { name: "silver", value: "#C0C0C0" },
  { name: "grey", value: "#999" },
  { name: "black", value: "#000000" },
  { name: "voilet", value: "#9932cc" },
  { name: "purple", value: "#A32CC4" },
  { name: "blue", value: "#0000FF" },
  { name: "green", value: "#00FF00" },
  { name: "beige", value: "#dbcaa9" },
  { name: "yellow", value: "#FDFF00" },
  { name: "golden", value: "#ffd700" },
  { name: "bronze", value: "#cb7f3b" },
  { name: "brown", value: "#a06b5e" },
  { name: "orange", value: "#ED7014" },
  { name: "wine", value: "#722f37" },
  { name: "maroon", value: "#900" },
  { name: "red", value: "#FF0000" },
];
