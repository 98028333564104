import { useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import constant from "../js/constant";
import { ApiPostMethod } from "../js/helper";
import "./userProfile.css";
import Spinner from "react-bootstrap/Spinner";
import notfoundImage from "../../image/not-foundimg.png";
import LoginModal from "../login/loginmodal";
import ViewPlansModel from "./viewPlansModal";

import { NavLink } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";

const ViewPlans = () => {
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [showselectedId, setShowselectedId] = useState("");
  const [showselectedcity, setShowselectedcity] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handlePlansModalClose = () => {
    setShowPlansModal(false);
  };

  const handlePlansModalShow = (id) => {
    setShowPlansModal(true);
    setShowselectedId(id._id);
    setShowselectedcity(id.city);
  };

  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleModalClose = () => {
    setShowLoginModal(false);
  };

  const handleModalShow = () => {
    setShowLoginModal(true);
  };

  const userIdFCPost = localStorage.hasOwnProperty("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "";
  const [errorMessage, setErrorMessage] = useState(false);
  const [userPostedCar, setUserPostedCar] = useState([]);
  const [userPostedDate, setUserPostedDate] = useState();
  const navigate = useNavigate();

  const formData = new FormData();
  useEffect(() => {
    setIsLoading(true);
    let data = formData;
    let url = `${constant.baseurl}viewAllCarPost/` + userIdFCPost._id;
    ApiPostMethod(url, data).then((response) => {
      if (response.error == false) {
        setErrorMessage(false);
        setUserPostedCar(response.carPostAppointment);
        setIsLoading(false);
      } else {
        setErrorMessage(true);
        setIsLoading(false);
      }
    });
  }, []);

  const postsByDate = {};
  userPostedCar.forEach((post) => {
    const options = {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    const date =
      post.errorAppointment === false
        ? new Date(post.carPost.updatedAt).toLocaleDateString("en-US", options)
        : "";
    if (!postsByDate[date]) {
      postsByDate[date] = [];
    }
    postsByDate[date].push(post);
  });

  const sortedDates = Object.keys(postsByDate).sort((a, b) => {
    return new Date(b) - new Date(a);
  });

  return (
    <>
      <Breadcrumb className="bread_crumb">
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>plans</Breadcrumb.Item>
      </Breadcrumb>

      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" variant="danger" />
        </div>
      ) : (
        <Container fluid>
          {localStorage.hasOwnProperty("user") ? (
            errorMessage ? (
              <Col
                lg="12"
                className="not-found d-flex justify-content-center align-items-center"
              >
                <Col lg="8" className="text-center">
                  <div className="notfound-img d-flex justify-content-center">
                    <img src={notfoundImage} alt="car Not Found" />
                  </div>
                  <h5 className="text-color font-weight-bold">
                    No Car Posted !!!
                  </h5>
                  <h6 className="text-grey">Please post your Car.</h6>
                  <NavLink to="/carPost" className="btn-cars_cars">
                    Sell Car
                  </NavLink>
                </Col>
              </Col>
            ) : (
              <div className="CarpostListGroup">
                {sortedDates.map((date) => (
                  <div key={date} className="post-group">
                    <h5 className="post-date">{date}</h5>
                    <ul className="CarpostList p-0">
                      {postsByDate[date].map((post, index) =>
                        post.carPost.status == "posted" ? (
                          <>
                            <li
                              key={index}
                              className="post-list-item"
                              onClick={() => handlePlansModalShow(post.carPost)}
                            >
                              <Col
                                lg="8"
                                className="userPost "
                              >
                                <Col className="d-flex align-items-center">
                                <Col lg="6" className="postedcarImage">
                                  <img
                                    src={post.carPost.carImages[0]}
                                    alt=""
                                    className="w-100"
                                  />
                                </Col>
                                <Col lg="6" className="postedCarDetails">
                                  <h5>
                                    <span>{post.brand.brandName} </span>
                                    <span> {post.carPost.model}</span>{" "}
                                    <span>{post.carPost.year}</span>
                                    <span className="text-uppercase">{post.carPost.carNumber
                                      ? post.carPost.carNumber
                                          .replace(/\s/g, "")
                                          .substring(0, 4) + "-XX-XXXX"
                                      : ""}
                                    </span>
                                  </h5>
                                  <h6>
                                    <span>
                                      {(post.carPost.kms / 1000).toLocaleString(
                                        "en-US",
                                        { maximumFractionDigits: 1 }
                                      ) + "k"}{" "}
                                      km{" "}
                                    </span>
                                    <span> {post.carPost.varient} </span>{" "}
                                    <span> {post.carPost.transmission} </span>
                                  </h6>
                                  <h6>
                                    <span>
                                      <svg
                                        width="19"
                                        height="20"
                                        viewBox="0 0 19 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g clip-path="url(#clip0_1204_2121)">
                                          <path
                                            d="M16.625 8.50793C16.625 14.0496 9.5 18.7996 9.5 18.7996C9.5 18.7996 2.375 14.0496 2.375 8.50793C2.375 6.61827 3.12567 4.806 4.46186 3.4698C5.79806 2.1336 7.61033 1.38293 9.5 1.38293C11.3897 1.38293 13.2019 2.1336 14.5381 3.4698C15.8743 4.806 16.625 6.61827 16.625 8.50793Z"
                                            stroke="#333333"
                                            stroke-width="1.61006"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M9.5 10.8829C10.8117 10.8829 11.875 9.81961 11.875 8.50793C11.875 7.19626 10.8117 6.13293 9.5 6.13293C8.18832 6.13293 7.125 7.19626 7.125 8.50793C7.125 9.81961 8.18832 10.8829 9.5 10.8829Z"
                                            stroke="#333333"
                                            stroke-width="1.61006"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_1204_2121">
                                            <rect
                                              width="19"
                                              height="19"
                                              fill="white"
                                              transform="translate(0 0.591309)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </span>{" "}
                                    Cars 2 Cars Hub, {post.carPost.city}
                                  </h6>
                                </Col>
                                </Col>
                              </Col>
                            </li>
                            <ViewPlansModel
                              showAllPlan={showPlansModal}
                              closeAllPlan={handlePlansModalClose}
                              carPostId={showselectedId}
                              city={showselectedcity}
                            />
                          </>
                        ) : (
                          ""
                        )
                      )}
                    </ul>
                  </div>
                ))}
              </div>
            )
          ) : (
            <>
              <Col
                lg="12"
                className="not-found d-flex flex-column justify-content-center align-items-center"
                style={{ height: "70vh" }}
              >
                <h5 className="text-color font-weight-bold">Login First !!!</h5>
                <button className="col-1 btn-plan" onClick={handleModalShow}>
                  Login
                </button>
                <LoginModal
                  show={showLoginModal}
                  handleClose={handleModalClose}
                />
              </Col>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default ViewPlans;
