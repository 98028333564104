import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { FaArrowRight } from "react-icons/fa";
import { FaAward } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Accordion from "react-bootstrap/Accordion";
import Spinner from "react-bootstrap/Spinner";
import contactimg from "../../image/contactform11.png";
import "./index.css";
import badge from "../index/inspectbadge.png"
import verified from "../index/verified.jpg"
import getImageColor from 'get-image-color';
import {

  Col,
  Card,
  Tabs,
  Tab,
  Image,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ApiGetMethod, ApiPostMethod } from "../js/helper";
import constant from "../js/constant";
import { NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import WishlistButton from "../sharemodal/whishlistButton";
import BodyType from "../bodyType/bodyType";
import uspimg1 from "../../image/uspimg1.png";
import uspimg2 from "../../image/uspimg2.png";
import "../blog.css";
import { BuyCar, FaqData, SellCar } from "../blogdata";
import Button from 'react-bootstrap/Button';
import Registermodal from "../register/registerModal";
import BannerSlide from "./banner";

const Index = () => {
  const location = useLocation();
  const query_Params = new URLSearchParams(location.search);
  const refer = query_Params.get("referid");

  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showloginModel, setShowloginModel] = useState(false);

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const section = searchParams.get("section");
    if (section) {
      const targetElement = document.getElementById(section);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (refer) {
      setShowRegisterModal(true);
    } else {
      setShowRegisterModal(false);
    }
  }, []);

  const handleRegisterClose = () => {
    setShowRegisterModal(false);
    setShowloginModel(false);
  };

  const formData = new FormData();
  const navigate = useNavigate();

  const navigateBlog = () => {
    // 👇️ navigate to /
    navigate("/Blog");
  };

  const [postedCars, setPostedCars] = useState([]);
  const [quickSell, setQuickSell] = useState([]);
  const [referralData, setReferralData] = useState([]);
  // const [leads, setLeads] = useState([]);
  const [homeCity, setHomeCity] = useState(
    localStorage.getItem("homeCity" || "pune")
  );
  const userInfo = localStorage.hasOwnProperty("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "";
  const [bannerdata, setBannerData] = useState([]);

  useEffect(() => {
    let data = formData;
    let url = `${constant.baseurl}banners`;
    ApiGetMethod(url, data).then((response) => {
      if (response.error == false) {
        setBannerData(response.banners);
      } else {
      }
    });
  }, []);

  const Carousel = {
    dots: true,
    arrows: false,
    infinite: true,
    loop: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  const tabs = {
    loop: true,
    autoplay: true,
    autoplaySpeed: 2500,
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    className: "center",
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 4,
    lazyLoad: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  // const luxarybrand = {
  //   infinite: true,
  //   arrows: false,
  //   loop: true,
  //   speed: 2500,
  //   className: "center",
  //   slidesToShow: 7,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   lazyLoad: false,
  //   autoplaySpeed: 2000,

  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 1,
  //         infinite: false,
  //       },
  //     },
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: false,
  //         autoplay: true,
  //         loop: true,
  //         speed: 2500,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: false,
  //         autoplay: true,
  //         loop: true,
  //         speed: 2500,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         infinite: false,
  //         autoplay: true,
  //         loop: true,
  //         speed: 2500,
  //       },
  //     },
  //   ],
  // };

  const luxarybrand = {
    dots: true,
    arrows: false,
    infinite: false,
    loop: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    lazyLoad: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
    ],
  };


  const brand = {
    infinite: true,
    arrows: false,
    loop: true,
    speed: 2500,
    className: "center",
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    lazyLoad: false,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          autoplay: true,
          loop: true,
          speed: 2500,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          autoplay: true,
          loop: true,
          speed: 2500,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          autoplay: true,
          loop: true,
          speed: 2500,
        },
      },
    ],
  };

  const Blogs = {
    dots: true,
    arrows: true,
    infinite: true,
    loop: true,
    speed: 2500,
    autoplay: true,
    autoplaySpeed: 2500,
    className: "center",
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 3,
    lazyLoad: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          loop: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          autoplay: true,
          loop: true,
          speed: 2500,
          dots:false
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          autoplay: true,
          loop: true,
          speed: 2500,
          dots:false
        },
      },
    ],
  };

  const MainBlogs = {
    dots: true,
    arrows: false,
    infinite: true,
    loop: true,
    speed: 2500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // loop: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          autoplay: true,
          loop: true,
          speed: 2500,
          dots:false
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          loop: true,
          speed: 2500,
          dots:false
        },
      },
    ],
  };

  const QuickSell = {
    dots: true,
    arrows: false,
    infinite: false,
    loop: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    lazyLoad: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  const Usp = {
    dots: true,
    arrows: true,
    infinite: true,
    loop: true,
    speed: 2500,
    autoplay: true,
    autoplaySpeed: 2500,

    slidesToShow: 2,
    slidesToScroll: 2,

    lazyLoad: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [isLoading, setIsLoading] = useState(false);
  const [loading,setLoading]=useState(false);

  useEffect(() => {
    setLoading(true);
    formData.append("city", homeCity);
    let data = formData;
    let url = `${constant.baseurl}viewPostedCars`;
    ApiPostMethod(url, data).then((response) => {
      if (response.error == false) {
        setPostedCars(response.postedCarsList);
        setQuickSell(response.quickSellCars);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const [carBrands, setCarBrands] = useState([]);


  useEffect(() => {
    setIsLoading(true);
    try {
      formData.append("brandType", "Top");
      formData.append("brandType", "Other");
      formData.append("brandType", "Luxury");
      let data = formData;
      let url = `${constant.baseurl}brandType`;
      ApiPostMethod(url, data)
        .then((response) => {
          if (response.error) {
            return;
          }
          const carbrands = response.brand;
          setCarBrands(carbrands);
          setIsLoading(false);
          for (let i = 0; i < carbrands.length; i++) {
            const brand = carbrands[i];
            const brandName = brand.brand.brandName;
            const models = brand.model;
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  const brandType = carBrands;

  const topBrands = brandType.filter(
    (brand) =>
      brand.brand.brandType === "Top" || brand.brand.brandType === "Other"
  );
  const luxuryBrands = brandType.filter(
    (brand) => brand.brand.brandType === "Luxury"
  );

  const filteredTopBrands = topBrands.filter((brand) =>
    brand.brand.brandName.toLowerCase()
  );

  const filteredLuxuryBrands = luxuryBrands.filter((brand) =>
    brand.brand.brandName.toLowerCase()
  );

  //  <---------blogs------------>

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    let url = `${constant.baseurl}viewAllBlogs`;
    ApiGetMethod(url).then((response) => {
      if (response.error == false) {
        setBlogs(response.Blogs);
      } else {
      }
    });
  }, []);

  //  <---------testimonial------------>

  const [testimonial, setTestimonial] = useState([]);

  useEffect(() => {
    let url = `${constant.baseurl}viewAlltestimonials`;
    ApiGetMethod(url).then((response) => {
      if (response.error == false) {
        setTestimonial(response.Testimonial);
      } else {
      }
    });
  }, []);

  // help and contact

  const [websiteLinks, setWebsiteLinks] = useState({});

  useEffect(() => {
    let data = formData;
    let url = `${constant.baseurl}settings`;
    ApiGetMethod(url, data).then((response) => {
      if (response.error == false) {
        setWebsiteLinks(response.data);
      } else {
      }
    });
  }, []);

  
  return (
    <>
      <div className="container-fluid home-page top_class">
        {/* <Slider {...Carousel} className="banner_carousel m-0">
          {bannerdata.map((banner, index) => (
            <div key={index}>
              <div className="carousel-slide d-flex justify-content-center">
                {banner.image.endsWith('.mp4')  ? <video src={banner.image} controls autoPlay></video> : <img src={banner.image} />}
                <div className="carousel-text">
                  <h1  style={{ color: textColor }}>{banner.bannerName}</h1>
                  <Button className="carousel-btn" onClick={() => openUrlInNewTab(banner.url)}>
                {banner.title}
              </Button>
                </div>
              </div>
            </div>
          ))}
        </Slider> */}
        <Slider {...Carousel} className="banner_carousel m-0">
          {bannerdata.map((banner, index) => (
            <BannerSlide key={index} banner={banner} />
          ))}
        </Slider>
        <Col lg="12" className="mt-2">
          <Col lg="12">
         
                  <div className="d-flex align-items-center justify-content-center heading-container">
      <Col lg="10" className="headings p-3">
        <Col lg="12" className="overlay-line justify-content-center d-flex align-items-center">
          <h2 className="heading-text_b m-0 text-center">QUICK SELL CARS</h2>
        </Col>
      </Col>

    </div>
            <Col lg="12" className="carsCarousel quicksell_car">
           
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" variant="danger" />
                </div>
              ) : (
                <Slider {...QuickSell}>
                  {quickSell.length > 0 ? (
                    quickSell.map((postedcar, index) => (
                      <div key={index}>
                        <div className="carousel-slide quick-sell-slide">
                          <div className="col-12 car-details quick-car" style={{overflow:"hidden",position:"relative"}}>
                            {
                            postedcar.carDetails.city === 'pune'?
                            <div className="inspected_badge">
                             <div>
                              {/* <FaAward/> */}
                              <Image class="badge_img" src={badge} style={{height:'22px'}} />
                              {/* <Image class="badge_img" src={verified} /> */}
                              </div> </div>: ""
                            }
                            <img
                              src={postedcar.carDetails.carImages[0]}
                              alt=""
                              onClick={() =>
                                navigate(
                                  "/SingleCarDetail?carPostId=" +
                                    postedcar.carDetails._id
                                )
                              }
                            />
                            <Col lg="12" className="details">
                              <Col
                                lg="12"
                                className="d-flex justify-content-between"
                              >
                                <Col
                                  lg="10"
                                  onClick={() =>
                                    navigate(
                                      "/SingleCarDetail?carPostId=" +
                                        postedcar.carDetails._id
                                    )
                                  }
                                >
                                  <h5 className="mb-1">
                                    {postedcar.carDetails.brandId}{" "}
                                    {postedcar.carDetails.year}
                                  </h5>
                                  <h6 className="mb-2">
                                    {postedcar.carDetails.model}{" "}
                                    {postedcar.carDetails.varient} -{" "}
                                    <span className="text-uppercase">{postedcar.carDetails.carNumber
                                      ? postedcar.carDetails.carNumber
                                          .replace(/\s/g, "")
                                          .substring(0, 4) + "-XX-XXXX"
                                      : ""}</span>
                                  </h6>
                                </Col>
                                <Col
                                  lg="1"
                                  className={
                                    localStorage.hasOwnProperty("user")
                                      ? ""
                                      : "d-none"
                                  }
                                >
                                  <WishlistButton
                                    wishlist={postedcar.wishlisted}
                                    uPostId={postedcar.carDetails._id}
                                    userId={userInfo._id}
                                  />
                                </Col>
                              </Col>
                              <Col lg="12">
                                <h6>
                                  {(
                                    postedcar.carDetails.kms / 1000
                                  ).toLocaleString("en-US", {
                                    maximumFractionDigits: 1,
                                  }) + "k"}{" "}
                                  km{" "}
                                  <span>
                                    <svg
                                      width="5"
                                      height="5"
                                      viewBox="0 0 5 5"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        fill="black"
                                      />
                                    </svg>
                                  </span>{" "}
                                  <span className="text-capitalize">
                                    {postedcar.fuelType}
                                  </span>{" "}
                                  <span>
                                    <svg
                                      width="5"
                                      height="5"
                                      viewBox="0 0 5 5"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        fill="black"
                                      />
                                    </svg>
                                  </span>{" "}
                                  {postedcar.carDetails.transmission}
                                </h6>
                              </Col>
                            </Col>
                            <Col lg="12" className="p-2">
                              <svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_434_2834)">
                                  <path
                                    d="M18.3814 8.46854C18.3814 14.3784 10.783 19.444 10.783 19.444C10.783 19.444 3.18457 14.3784 3.18457 8.46854C3.18457 6.45331 3.98512 4.52062 5.4101 3.09564C6.83508 1.67066 8.76777 0.870117 10.783 0.870117C12.7982 0.870117 14.7309 1.67066 16.1559 3.09564C17.5809 4.52062 18.3814 6.45331 18.3814 8.46854Z"
                                    stroke="#333333"
                                    stroke-width="1.71704"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M10.7828 11.0014C12.1816 11.0014 13.3156 9.86743 13.3156 8.4686C13.3156 7.06977 12.1816 5.93579 10.7828 5.93579C9.38398 5.93579 8.25 7.06977 8.25 8.4686C8.25 9.86743 9.38398 11.0014 10.7828 11.0014Z"
                                    stroke="#333333"
                                    stroke-width="1.71704"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_434_2834">
                                    <rect
                                      width="20.2625"
                                      height="20.2625"
                                      fill="white"
                                      transform="translate(0.651611 0.026001)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              <span className="m-1">Cars 2 Cars Hub, {postedcar.carDetails.city}</span>
                            </Col>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h5 className="text-color text-center m-0 mt-2">
                      Cars Not Found
                    </h5>
                  )}
                </Slider>
              )}
            </Col>

            <div >
        {/* <NavLink to="/CarSearch" className="view_all" >
          View All
        </NavLink> */}
      </div>
            {/* <div className="d-flex align-items-baseline">
            <Col lg="10" className="headings p-3">
              <Col
                lg="12"
                className="overlay-line justify-content-center d-flex align-items-center"
              >
                <h2 className="heading-text_b m-0">Brand</h2>
              </Col>
            </Col>
            <h6 className="view-all-text ">
              <NavLink to="/CarSearch" className="text-danger">
                View All
              </NavLink>
            </h6>
            </div> */}
                              <div className="d-flex align-items-center justify-content-center heading-container">
      <Col lg="10" className="headings p-3">
        <Col lg="12" className="overlay-line justify-content-center d-flex align-items-center">
          <h2 className="heading-text_b m-0 text-center">Brand</h2>
        </Col>
      </Col>

      </div>
            <Col
              lg="12"
              className="d-flex justify-content-center brand-block flex-wrap"
              style={{padding:"0 2rem"}}
            > 
             <Col lg="12" >
            {isLoading ? (
              <div className="text-center">
                <Spinner animation="border" variant="danger" />
              </div>
            ) : (
              <Slider {...brand}>
                {filteredTopBrands.length > 0 ? (
                  filteredTopBrands.slice(0, 7).map((brand, index) => (
                    <Col
                      lg="2"
                      className="brand_item"
                    
                      key={index}
                      onClick={() => navigate("/CarSearch?brand=" + brand.brand.brandName)}
                    >
                      <img src={brand.brand.brandImage} alt="" />
                      <div className="brand-details col-12 mt-2">
                        <h6 className="brand-name text-center">
                          {brand.brand.brandName}
                        </h6>
                        <h6 className="car-count text-center">
                          {brand.noOfCars} cars
                        </h6>
                      </div>
                    </Col>
                  ))
                ) : (
                  <h5 className="text-color text-center m-0 mt-2 w-100">
                    Brands Not Found
                  </h5>
                )}
              </Slider>
            )}
            </Col> 
            </Col>
            <div >
    
    
        <NavLink to="/CarSearch" className="view_all" >
          View All
        </NavLink>
      </div>

          
       <div className="d-flex align-items-center justify-content-center heading-container">
      <Col lg="10" className="headings p-3">
        <Col lg="12" className="overlay-line justify-content-center d-flex align-items-center">
          <h2 className="heading-text_b m-0 text-center">Recently Added Cars</h2>
        </Col>
      </Col>
      
    </div>
            <Col
              lg="12"
              className="carsCarousel recently-added luxary_brand_top"
            >
            
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" variant="danger" />
                </div>
              ) : (
                <Slider {...QuickSell}>
                  {postedCars.length > 0 ? (
                    postedCars.map((postedcar, index) => (
                      <div key={index}>
                        <div className="carousel-slide recently-added-slide " style={{overflow:"hidden",position:"relative"}}>
                        {
                            postedcar.carDetails.city === 'pune'?
                            <div className="inspected_badge" >
                             <div>
                              {/* <FaAward/> */}
                              <Image class="badge_img" src={badge} style={{height:"22px"}} />
                              </div> </div>: ""
                            }
                          <div className="col-12 car-details quick-car">
                        
                            <img
                              src={postedcar.carDetails.carImages[0]}
                              alt=""
                              onClick={() =>
                                navigate(
                                  "/SingleCarDetail?carPostId=" +
                                    postedcar.carDetails._id
                                )
                              }
                            />
                            <Col lg="12" className="details">
                              <Col
                                lg="12"
                                className="d-flex justify-content-between"
                              >
                                <Col
                                  lg="11"
                                  onClick={() =>
                                    navigate(
                                      "/SingleCarDetail?carPostId=" +
                                        postedcar.carDetails._id
                                    )
                                  }
                                >
                                  <h5 className="mb-1">
                                    {postedcar.carDetails.brandId}{" "}
                                    {postedcar.carDetails.year}
                                  </h5>
                                  <h6 className="mb-2">
                                    {postedcar.carDetails.model}{" "}
                                    {postedcar.carDetails.varient} -{" "}
                                    <span className="text-uppercase">{postedcar.carDetails.carNumber
                                      ? postedcar.carDetails.carNumber
                                          .replace(/\s/g, "")
                                          .substring(0, 4) + "-XX-XXXX"
                                      : ""}</span>
                                  </h6>
                                </Col>
                                <Col lg="1">
                                  <WishlistButton
                                    wishlist={postedcar.wishlisted}
                                    uPostId={postedcar.carDetails._id}
                                    userId={userInfo._id}
                                  />
                                </Col>
                              </Col>
                              <Col lg="12">
                                <h6>
                                  {(
                                    postedcar.carDetails.kms / 1000
                                  ).toLocaleString("en-US", {
                                    maximumFractionDigits: 1,
                                  }) + "k"}{" "}
                                  km{" "}
                                  <span>
                                    <svg
                                      width="5"
                                      height="5"
                                      viewBox="0 0 5 5"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        fill="black"
                                      />
                                    </svg>
                                  </span>{" "}
                                  <span className="text-capitalize">
                                    {postedcar.fuelType}
                                  </span>{" "}
                                  <span>
                                    <svg
                                      width="5"
                                      height="5"
                                      viewBox="0 0 5 5"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        fill="black"
                                      />
                                    </svg>
                                  </span>{" "}
                                  {postedcar.carDetails.transmission}
                                </h6>
                              </Col>
                            </Col>
                            <Col lg="12" className="p-2">
                              <svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_434_2834)">
                                  <path
                                    d="M18.3814 8.46854C18.3814 14.3784 10.783 19.444 10.783 19.444C10.783 19.444 3.18457 14.3784 3.18457 8.46854C3.18457 6.45331 3.98512 4.52062 5.4101 3.09564C6.83508 1.67066 8.76777 0.870117 10.783 0.870117C12.7982 0.870117 14.7309 1.67066 16.1559 3.09564C17.5809 4.52062 18.3814 6.45331 18.3814 8.46854Z"
                                    stroke="#333333"
                                    stroke-width="1.71704"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M10.7828 11.0014C12.1816 11.0014 13.3156 9.86743 13.3156 8.4686C13.3156 7.06977 12.1816 5.93579 10.7828 5.93579C9.38398 5.93579 8.25 7.06977 8.25 8.4686C8.25 9.86743 9.38398 11.0014 10.7828 11.0014Z"
                                    stroke="#333333"
                                    stroke-width="1.71704"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_434_2834">
                                    <rect
                                      width="20.2625"
                                      height="20.2625"
                                      fill="white"
                                      transform="translate(0.651611 0.026001)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              <span className="m-1">Cars 2 Cars Hub, {postedcar.carDetails.city}</span>
                            </Col>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h5 className="text-color text-center m-0 mt-2">
                      Cars Not Found
                    </h5>
                  )}
                </Slider>
              )}
            </Col>
            <div >
        <NavLink to="/CarSearch" className="view_all" >
          View All
        </NavLink>
      </div>

                 <div className="d-flex align-items-center justify-content-center heading-container">
      <Col lg="10" className="headings p-3">
        <Col lg="12" className="overlay-line justify-content-center d-flex align-items-center">
          <h2 className="heading-text_b m-0 text-center">Luxury Brands</h2>
        </Col>
      </Col>

      </div>
            <Col
              lg="12"
              className="d-flex justify-content-center brand-block flex-wrap"
              style={{padding:"0 2rem"}}
            >
    <Col lg="12" style={{ margin: "0 1rem" }}>
            {isLoading ? (
              <div className="text-center">
                <Spinner animation="border" variant="danger" />
              </div>
            ) : filteredLuxuryBrands.length > 0 ? (
              <Slider {...luxarybrand}>
                {filteredLuxuryBrands.slice(0, 7).map((brand, index) => (
                  <Col
                    lg="2"
                    className="brand_item"
                    key={index}
                    onClick={() => navigate("/LuxuryCarSearch?brand=" + brand.brand.brandName)}
                  >
                    <img src={brand.brand.brandImage} alt="" />
                    <div className="brand-details col-12 mt-2">
                      <h6 className="brand-name text-center">
                        {brand.brand.brandName}
                      </h6>
                      <h6 className="car-count text-center">
                        {brand.noOfCars} cars
                      </h6>
                    </div>
                  </Col>
                ))}
              </Slider>
            ) : (
              <h5 className="text-color text-center m-0 mt-2 w-100">
                Brands Not Found
              </h5>
            )}
            </Col>
            </Col>
        <div >
        <NavLink to="/LuxuryCarSearch" className="view_all" >
          View All
        </NavLink>
      </div>


      <div className="d-flex align-items-center justify-content-center heading-container">
      <Col lg="10" className="headings p-3">
        <Col lg="12" className="overlay-line justify-content-center d-flex align-items-center">
          <h2 className="heading-text_b m-0 text-center">USP</h2>
        </Col>
      </Col>

    </div>
            <Col lg="10" className="carsCarousel recently-added m-auto usp-slider" style={{height:"150px"}}>
              <Slider {...Usp} className="uspSlide" style={{height:"150px"}}>
                <div>
                  <div className="carousel-slide"  style={{height:"150px"}}>
                    <img src={uspimg1} style={{height:"130px",borderRadius:'20px'}} />
                    <div className="carousel-text w-100">
                      <h6 className="text-center">LUXURY CARS</h6>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="carousel-slide"  style={{height:"150px"}}>
                    <img src={uspimg2}  style={{height:"130px",borderRadius:'20px'}}/>
                    <div className="carousel-text w-100">
                      <h6 className="text-center">Buy your dream Car</h6>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="carousel-slide"  style={{height:"150px"}}>
                    <img src={uspimg1} style={{height:"130px",borderRadius:'20px'}} />
                    <div className="carousel-text w-100">
                      <h6 className="text-center">
                        Sell your car for the best price
                      </h6>
                    </div>
                  </div>
                </div>
              </Slider>
            </Col>
            <div className="d-flex align-items-center justify-content-center heading-container">
      <Col lg="10" className="headings p-3">
        <Col lg="12" className="overlay-line justify-content-center d-flex align-items-center">
          <h2 className="heading-text_b m-0 text-center">Body Type</h2>
        </Col>
      </Col>

    </div>
            <Col lg="12">
              <BodyType />
            </Col>
            {/* how it works */}

            <div className="d-flex align-items-center justify-content-center heading-container">
      <Col lg="10" className="headings p-3">
        <Col lg="12" className="overlay-line justify-content-center d-flex align-items-center">
          <h2 className="heading-text_b m-0 text-center">How it works</h2>
        </Col>
      </Col>

    </div>

            <Col lg="12" style={{ padding: "0 2rem" }}>
              <Tabs
                className="home_page_tabs"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tab
                  eventKey="first"
                  className="tab_first"
                  title={
                    isMobileView ? "Buy your car" : "Buy car in easy Steps"
                  }
                  // title={getTitleForMobileView(
                  //   "Buy car in easy Steps",
                  //   "Buy your car"
                  // )}
                >
                  <Col lg="12" className="carsCarousel recently-added">
                    <Slider {...tabs}>
                      {BuyCar.map((buycar, i) => (
                        <Card
                          className="sellcar_card"
                          style={{ width: "auto" }}
                        >
                          <img
                            className="sellcar_image"
                            src={buycar.image}
                            style={{
                              verticalAlign: "center",
                              height: "200px",
                              width: "auto",
                              margin:"auto"
                            }}
                          />

                          <Card.Body style={{ height: "140px" }}>
                            <Card.Title className="buycar_card_title">
                              {buycar.title}
                            </Card.Title>
                            <Card.Text className="buycar_carda_text">
                              {buycar.text}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      ))}
                    </Slider>
                  </Col>
                </Tab>
                <Tab
                  eventKey="secound"
                  title={
                    isMobileView
                      ? "Sell your car"
                      : "Sell your car in easy Steps"
                  }
                >
                  <Col lg="12" className="carsCarousel recently-added">
                    <Slider {...tabs}>
                      {SellCar.map((sellcar, i) => (
                        <Card className="sellcar_card">
                          <img
                            className="sellcar_image"
                            src={sellcar.image}
                            style={{
                              verticalAlign: "center",
                              height: "200px",
                              width: "auto",
                              margin:"auto"
                            }}
                          />

                          <Card.Body style={{ height: "140px" }}>
                            <Card.Title className="buycar_card_title">
                              {sellcar.title}
                            </Card.Title>
                            <Card.Text className="buycar_carda_text">
                              {sellcar.text}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      ))}
                    </Slider>
                  </Col>
                </Tab>
              </Tabs>
            </Col>

            {/* what motivates us */}

            <div className="d-flex align-items-center justify-content-center heading-container">
      <Col lg="10" className="headings p-3">
        <Col lg="12" className="overlay-line justify-content-center d-flex align-items-center">
          <h2 className="heading-text_b m-0 text-center">What Motivates Us</h2>
        </Col>
      </Col>

    </div>
            <Col lg="12" className="carsCarousel recently-added">
              <div className="blog_back" style={{backgroundSize:"cover"}}>
                <Slider {...Blogs}>
                  {testimonial.map((testimonial, i) => (
                    <div className="carousel-slide quick-sell-slide" key={i}>
                      <Card className="rounded-4 blogcard ">
                        <Card.Body
                          style={{
                           
                            display: " flex",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden",
                            padding: "0",
                            borderRadius: "12px 12px 0 0 ",
                            height: "190px",
                          }}
                        >
                          <Card.Img
                            src={testimonial.testimonialImage}
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "initial",
                            }}
                          />
                        </Card.Body>

                        <Card.Body className="border border-bottom-2" style={{height:"160px"}}>
                          <Card.Text className="homepage_blog_text" style={{fontSize:"16px"}}>
                            {testimonial.description
                              ? testimonial.description.length > 250
                                ? `${testimonial.description.substring(
                                    0,
                                    170
                                  )}...`
                                : testimonial.description
                              : ""}
                          </Card.Text>
                        </Card.Body>

                        <Card.Body style={{height:"65px",paddingTop:"7px"}}>
                          <Card.Img
                            className="sm-avtar m-0"
                            src="/user_avtar.png"
                            style={{
                              width: "20px",
                              height: "20px",
                              display: "inline-block",
                            }}
                          />

                          <Card.Text
                            style={{
                              display: "inline-block",
                              marginLeft: "5px",
                              marginBottom:"7px"
                            }}
                          >
                            {testimonial.buyer}
                            <span
                              style={{
                                padding: "2px 10px",
                                marginLeft: "5px",
                                borderLeft: "1px solid #000",
                              }}
                            >
                              {" "}
                              {testimonial.city}
                            </span>
                          </Card.Text>
                          <Card.Title style={{ fontSize: "1rem" ,marginLeft:"1.5rem"}}>
                            {testimonial.carName}
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </Slider>
              </div>
            </Col>

            {/* frequently asked question */}

           

            <div className="d-flex align-items-center justify-content-center heading-container">
      <Col lg="10" className="headings p-3">
        <Col lg="12" className="overlay-line justify-content-center d-flex align-items-center">
          <h2 className="heading-text_b m-0 text-center">FREQUENTLY ASK QUESTIONS</h2>
        </Col>
      </Col>

    </div>
            <Col lg="12" style={{padding:"0 2rem"}}>
              <Accordion defaultActiveKey="0" >
                {FaqData.map((faq, i) => (
                  <Accordion.Item eventKey={i} key={i} className="m-1 ">
                    <Accordion.Header className="faq-que ">
                      {faq.Quetion}
                    </Accordion.Header>
                    <Accordion.Body>{faq.Answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>

            {/* home page blog */}
          
   <div className="d-flex align-items-center justify-content-center heading-container">
      <Col lg="10" className="headings p-3">
        <Col lg="12" className="overlay-line justify-content-center d-flex align-items-center">
          <h2 className="heading-text_b m-0 text-center">BLOGS</h2>
        </Col>
      </Col>
    </div>
            
            <Col
              lg="12"
              className="carsCarousel recently-added blog_carousel"
              style={{ padding: "0 1.4rem" }}
            >
            
              <Slider {...MainBlogs}>
                {blogs.map((blogs, i) => (
                  <div
                    key={i}
                    onClick={() =>
                      navigate("/singleBlogDescription?blogId=" + blogs._id)
                    }
                  >
                    <Card className="rounded-4 main_blog">
                      <Card.Body
                        style={{
                          height: "100%",
                          display: " flex",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden",
                          padding: "0",
                          borderRadius: "12px 12px 0 0 ",
                          height: "130px",
                        }}
                      >
                        <Card.Img
                          src={blogs.blogImage}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "initial",
                          }}
                        />
                      </Card.Body>

                      <Card.Body
                        className="border border-bottom-2 "
                        style={{ height: "116px" }}
                      >
                        <Card.Title style={{ fontSize: " 18px" }}>
                          {blogs.title
                            ? blogs.title.length > 25
                              ? `${blogs.title.substring(0, 25)}...`
                              : blogs.title
                            : ""}
                        </Card.Title>
                        <Card.Text
                          style={{
                            fontFamily: "poppins",
                            textAlign: "left",
                            fontSize: "  14px",
                            // lineHeight: "24px",
                          }}
                        >
                          {blogs.description
                            ? blogs.description.length > 97
                              ? `${blogs.description.substring(0, 97)}...`
                              : blogs.description
                            : ""}
                        </Card.Text>
                      </Card.Body>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "10px",
                        }}
                      >
                        <Card.Title
                          style={{
                            paddingLeft: " 1rem",
                            fontSize: "1rem",
                            color: "#7F7F7F",
                          }}
                        >
                          {`${new Date(blogs.updatedAt).toLocaleDateString(
                            "en-US",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )} `}
                        </Card.Title>
                        <FaArrowRight color=" rgb(127, 127, 127)" />
                      </div>
                    </Card>
                  </div>
                ))}
              </Slider>
            </Col>
            <NavLink to="/Blog" className="view_all" >
          View All
        </NavLink>


            {/* contact bottom */}

            <Col lg="12" className="p-4 m-2" id="Help">
              <Col lg="12" className="contact_parent_div">
                <Col lg="10" className="contact_wrapper"></Col>

                <Col lg="11" className="contact_inner">
                  <Col className="d-inline-block contact_inner_left">
                    <div>
                      <img src={contactimg} style={{ width: "100%" }} />
                    </div>
                  </Col>
                  <Col className="d-inline-block contact_inner_right">
                    <div>
                      <h5>For any quary contact to below number</h5>
                      <a
                        className="cont-num"
                        href={`tel:${websiteLinks.number}`}
                      >
                        <h4>{websiteLinks.number}</h4>
                      </a>
                      <h5>OR</h5>
                      <h5>Reach at our Email</h5>
                      <a
                        className="cont-num"
                        href={`mailto:${websiteLinks.email}`}
                      >
                        <h4>{websiteLinks.email}</h4>
                      </a>
                    </div>
                  </Col>
                </Col>
              </Col>
            </Col>
          </Col>
        </Col>
      </div>
      <Registermodal
        registerShow={showRegisterModal}
        registerClose={handleRegisterClose}
        referId={refer}
      />
    </>
  );
};

export default Index;
