import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./userProfile.css";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiPostMethod } from "../js/helper";
import constant from "../js/constant";

const PersonalInformation = () => {
  const [user, setUser] = useState({
    _id: "",
    name: "",
    mobile: "",
    email: "",
    address: "",
  });

  const [referedUsers, setReferedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const storedUser = localStorage.hasOwnProperty("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";

    if (storedUser) {
      setUser(storedUser);
      fetchReferedUsers(storedUser._id);
    }
  }, []);
  const formData = new FormData();

  const fetchReferedUsers = (userId) => {
    formData.append("userId", userId);
    const url = `${constant.baseurl}referedUser`;
    const data = formData;

    ApiPostMethod(url, data)
      .then((response) => {
        if (response.error === false) {
          setReferedUsers(response.users);
        } else {
          toast.error(response.message);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleUpdate = () => {
    setIsLoading(true);

    formData.append("userId", user._id);
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append("address", user.address);
    let data = formData;
    let url = `${constant.baseurl}editUser`;
    ApiPostMethod(url, data).then((response) => {
      if (response.error == false) {
        localStorage.setItem("user", JSON.stringify(user));
        setIsLoading(false);
        toast.success(
          <>
            <h5 className="m-0">Success</h5>
            <h6 className="m-0">
              Your information has been successfully updated.
            </h6>
          </>,
          {
            position: "top-center",
            autoClose: 5000,
            theme: "light",
          }
        );
      } else {
        setIsLoading(false);
      }
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  return (
    <>
      <Breadcrumb className="bread_crumb">
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Personel Information</Breadcrumb.Item>
      </Breadcrumb>
      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" variant="danger" />
        </div>
      ) : (
        <Col lg="12" className="d-flex user-info flex-wrap user_profile_top">
          <label className="col-6 p-2">
            Name:
            <input
              type="text"
              name="name"
              value={user.name}
              onChange={handleInputChange}
              className="form-control"
            />
          </label>
          <label className="col-6 p-2">
            Phone:
            <input
              type="number"
              name="age"
              value={user.mobile}
              onChange={handleInputChange}
              className="form-control"
              readOnly
            />
          </label>
          <label className="col-6 p-2">
            Email:
            <input
              type="email"
              name="email"
              value={user.email}
              onChange={handleInputChange}
              className="form-control"
            />
          </label>
          <label className="col-6 p-2">
            Address:
            <input
              type="text"
              name="address"
              value={user.address}
              onChange={handleInputChange}
              className="form-control"
            />
          </label>
          <div className="p-2 text-center col-lg-12 mt-3">
            <button onClick={handleUpdate} className="buttn-color">
              Update
            </button>
          </div>
        </Col>
      )}

      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" variant="danger" />
        </div>
      ) : (
        <Col lg="12" className="p-2 mt-4">
          <h4 className="mb-3">Invitation List</h4>
          <div className="invitation-list-parent">
            {referedUsers.map((user) => (
              <Col key={user._id} lg="12" className="invitation-list-item">
                <ul>
                  <li>
                    <div className="col-lg-12 d-flex justify-content-between">
                      <div className="col-4 text-center">{user.name}</div>
                      <div className="col-4 text-center">
                        {String(user.mobile).replace(
                          /(?<=\d{2})\d(?=\d{2})/g,
                          "*"
                        )}
                      </div>
                      <div className="col-4 text-center">
                        {new Date(user.createdAt)
                          .toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })
                          .split("/")
                          .join("-")}
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
            ))}
          </div>
        </Col>
      )}

      <ToastContainer />
    </>
  );
};
export default PersonalInformation;
