import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import getImageColor from 'get-image-color';

const BannerSlide = ({ key, banner }) => {
    const [textColor, setTextColor] = useState('');
  
    useEffect(() => {
      const mediaElement = banner.image.endsWith('.mp4') ? document.createElement('video') : new Image();
      // mediaElement.src = banner.image;      
      mediaElement.src = banner.image;
      mediaElement.onload = () => {
        getImageColor(mediaElement, (colors) => {
          const averageColor = colors[0].color;
  
          // Determine whether to use dark or light text color
          const r = parseInt(averageColor.slice(1, 3), 16);
          const g = parseInt(averageColor.slice(3, 5), 16);
          const b = parseInt(averageColor.slice(5, 7), 16);
          const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  
          if (brightness > 128) {
            setTextColor('black');
          } else {
            setTextColor('white');
          }
        });
      };
    }, [banner.image]);

    const openUrlInNewTab = (url) => {
        window.open(url, '_blank');
      };
  
    return (
      <div key={key}>
        <div className="carousel-slide d-flex justify-content-center">
          {banner.image.endsWith('.mp4') ? (
            <video src={banner.image} controls autoPlay></video>
          ) : (
            <img src={banner.image} />
          )}
          <div className="carousel-text">
            <h1 style={{ color: `${textColor}` }}>{banner.bannerName}</h1>
            <Button className="carousel-btn" onClick={() => openUrlInNewTab(banner.url)}>
              {banner.title}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  export default BannerSlide;