import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import constant from "../js/constant";
import { ApiPostMethod } from "../js/helper";
import Login_Logo from "./Cars2Cars_Logo.png";
import "./register.css";
import { validate } from "../validator/validate";

const Register = () => {
  const [mobNo, setMobNo] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [refer, setRefer] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [error, setError] = useState({
    name: "",
    mobile: "",
    email: "",
    address: "",
    city: "",
    refer: "",
  });

  function handleError(e) {
    const { name, value } = e.target;

    setError((prevData) => ({
      ...prevData,
      [name]: validate(name, value),
    }));
  }

  const navigate = useNavigate();

  const handleMonNo = (e) => {
    handleError(e);
    setMobNo(e.target.value);
  };
  const handleName = (e) => {
    handleError(e);
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    handleError(e);
    setEmail(e.target.value);
  };
  const handleAddress = (e) => {
    handleError(e);
    setAddress(e.target.value);
  };
  const handleCity = (e) => {
    handleError(e);
    setCity(e.target.value);
  };
  const handleRefer = (e) => {
    handleError(e);
    setRefer(e.target.value);
  };

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("mobile", mobNo);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("refer", refer);
    let data = formData;
    let url = `${constant.baseurl}registerUser`;
    ApiPostMethod(url, data).then((response) => {
      if (response.error) {
        toast.danger(response.message, {
          position: "top-center",
          autoClose: 5000,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.success(response.message, {
          position: "top-center",
          autoClose: 5000,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => {
            navigate("/login");
          },
        });
      }
    });
  }
  return (
    <>
      <section className="register" id="register">
        <div className="container-fluid d-flex h-100">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <div className="col-4 register-form">
              <div className="register-logo">
                <img src={Login_Logo} alt="" />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Enter Number *"
                    name="mobile"
                    onChange={handleMonNo}
                    value={mobNo}
                  />
                  {error.mobile && (
                    <p className="text-danger mb-1">{error.mobile}</p>
                  )}
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Enter Name * "
                    name="name"
                    onChange={handleName}
                    value={name}
                  />
                  {error.name && (
                    <p className="text-danger mb-1">{error.name}</p>
                  )}
                  <input
                    type="email"
                    className="form-control mb-2"
                    placeholder="Enter Email *"
                    name="email"
                    onChange={handleEmail}
                    value={email}
                  />
                  {error.email && (
                    <p className="text-danger mb-1">{error.email}</p>
                  )}
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Enter Address *"
                    name="address"
                    onChange={handleAddress}
                    value={address}
                  />
                  {error.address && (
                    <p className="text-danger mb-1">{error.address}</p>
                  )}
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Enter City *"
                    name="city"
                    onChange={handleCity}
                    value={city}
                  />
                  {error.city && (
                    <p className="text-danger mb-1">{error.city}</p>
                  )}
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Enter Refer Number"
                    name="refer"
                    onChange={handleRefer}
                    value={refer}
                  />
                  {error.refer && (
                    <p className="text-danger mb-1">{error.refer}</p>
                  )}
                </div>
                {/* <div className="col-12 d-flex mb-4">
                                    <div className="col-8">
                                        <input type="text" className="form-control Otp-input" placeholder="OTP" />
                                    </div>
                                    <div className="col-4">
                                        <button className="btn btn-theme resendOtp">Resend OTP</button>
                                    </div>
                                </div> */}
                <div className="register-btn text-center">
                  <button type="submit" className="btn btn-theme">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};
export default Register;
