import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Link from "react-router-dom";
import "./layout.css";

import botsellcar from "../../image/bot_selcar.png";
import botbuycar from "../../image/bot_by_black.png";
import profile11 from "../../image/profile11.png";
import sellcar11 from "../../image/sellcar11.png";
import botbuycar11 from "../../image/bot_bycar.png";
import botprofile from "../../image/bot-profile.png";
import Nav from "react-bootstrap/Nav";
import LoginModal from "../login/loginmodal";
import { NavLink } from "react-router-dom";

const BottomNavbar = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleModalClose = () => {
    setShowLoginModal(false);
  };

  const handleModalShow = () => {
    setShowLoginModal(true);
  };
  const isMobileView = window.innerWidth; // Define the maximum width for mobile view
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    // Map the active tab based on the current URL
    if (currentPath === "/") {
      setActiveTab("Home");
    } else if (currentPath === "/Carpost") {
      setActiveTab("SellCar");
    } else if (currentPath === "/UserProfilePage") {
      setActiveTab("Profile");
    } else {
      setActiveTab("");
    }
  }, [location]);

  return (
    <>
      {isMobileView < 786 ? (
        <Nav className="bottom_nav">
          <Nav.Item
            className={activeTab === "Home" ? "active nav_item" : "nav_item"}
          >

            <NavLink className="bottom_ele" to="/"   onClick={handleClick}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 7.49984L10 1.6665L17.5 7.49984V16.6665C17.5 17.1085 17.3244 17.5325 17.0118 17.845C16.6993 18.1576 16.2754 18.3332 15.8333 18.3332H4.16667C3.72464 18.3332 3.30072 18.1576 2.98816 17.845C2.67559 17.5325 2.5 17.1085 2.5 16.6665V7.49984Z"
                stroke="#201F1F"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.5 18.3333V10H12.5V18.3333"
                stroke="#201F1F"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
              Home
              </NavLink>
          </Nav.Item>

          {localStorage.hasOwnProperty("user") ? (
            <Nav.Item
              className={
                activeTab === "SellCar" ? "active nav_item" : " nav_item"
              }
            >

              <NavLink className="bottom_ele" to="/Carpost"   onClick={handleClick}>
              <img src={activeTab === "SellCar" ? sellcar11 : botsellcar} />
                
                Sell Car</NavLink>
            </Nav.Item>
          ) : (
            <Nav.Item
              onClick={handleModalShow}
              className={
                activeTab === "SellCar" ? "active nav_item" : " nav_item"
              }
            >

              <NavLink className="bottom_ele" to=""   onClick={handleClick}> 
              <img src={activeTab === "SellCar" ? botbuycar : botsellcar} />
                
                Sell Car
                </NavLink>
            </Nav.Item>
          )}
          {localStorage.hasOwnProperty("user") ? (
            <Nav.Item
              className={
                activeTab === "BuyCar" ? "active nav_item" : "nav_item"
              }
            >

              <NavLink className="bottom_ele" to="/carSearch"   onClick={handleClick}>
              <img src={activeTab === "BuyCar" ? botbuycar11 : botbuycar} />
                
                Buy Car</NavLink>
            </Nav.Item>
          ) : (
            <Nav.Item
              onClick={handleModalShow}
              className={
                activeTab === "BuyCar" ? "active nav_item" : "nav_item"
              }
            >

              <NavLink className="bottom_ele"  to="">
              <img src={activeTab === "BuyCar" ? botbuycar11 : botbuycar} />
                
                Buy Car</NavLink>
            </Nav.Item>
          )}

          {localStorage.hasOwnProperty("user") ? (
            <Nav.Item
              className={
                activeTab === "Profile" ? "active nav_item" : "nav_item"
              }
            >
              <NavLink className="bottom_ele"  to="/UserProfilePage?activeTabKey=profileInformation"   onClick={handleClick}>
              <img src={activeTab === "Profile" ? profile11 : botprofile} />

                Profile
              </NavLink>
            </Nav.Item>
          ) : (
            <Nav.Item
              onClick={handleModalShow}
              className={
                activeTab === "Profile" ? "active nav_item" : "nav_item"
              }
            >
              <NavLink className="bottom_ele"  to="">
              <img src={botprofile} />
                Profile</NavLink>
            </Nav.Item>
          )}
        </Nav>
      ) : (
        ""
      )}
      <LoginModal show={showLoginModal} handleClose={handleModalClose} />
    </>
  );
};

export default BottomNavbar;

// .nav_item.active .nav-link {
//   color: #f00;
// }

// .nav_item img {
//   height: 20px;
//   width: 20px;
//   margin-bottom: 2px;
// }

// .nav_item {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }
