import React from "react";
import aboutus from "../image/aboutus.png";
import "./aboutus.css";
import { Col, Container } from "react-bootstrap";

const About = () => {
  return (
    <>
      <Container
        fluid
        className="p-4 top_class "
        style={{ background: " #F5F5F5" }}
      >
        <h1 className="aboutus_heading">About Us</h1>
        <Col className="about_img_banner">
          <img src={aboutus} alt="aboutus_banner" className="about_image" />
        </Col>
        <div className="col-10 para_wrapper about-us-block">
          <div className="bottom_para">
           
              
                <h5>
                  About Us :
                  <span className="subheading ps-1">
                    Welcome to Cars2Cars - Your Ultimate Online Car Bidding
                    Platform
                  </span>
                </h5>
                <p>
                  At Cars2Cars, we've reimagined the way you buy and sell cars.
                  Our cutting-edge online platform brings together car
                  enthusiasts, buyers, and sellers from all over, creating a
                  dynamic marketplace where every transaction is an exhilarating
                  journey. Whether you're on the hunt for your dream car or
                  looking to sell your vehicle seamlessly, Cars2Cars is your
                  destination of choice.
                </p>
             
              
                <h5>
                  Our Vision : <span className="subheading">Revolutionizing Car Transactions</span>
                </h5>
                <p>
                  Our vision is simple yet revolutionary: to transform the car
                  trading experience into an exciting and transparent process
                  for everyone involved. We believe that buying or selling a car
                  should be more than a mere transaction – it should be an
                  experience that excites, empowers, and connects individuals.
                </p>
             
              
                <h5>
                  How It Works : <span className="subheading">The Bidding Advantage</span>
                </h5>
                <p>
                  At Cars2Cars, we've replaced traditional car listings with an
                  innovative bidding process. Here's how it works :
                </p>
                <ol>
                  <li>
                    <h5>
                      List Your Car : 
                      <span className="subhead ps-1">
                        
                        Sellers can easily list their vehicles on our platform,
                        providing comprehensive details, high-quality images, and
                        any additional information that enhances their car's
                        appeal.
                      </span>
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Browse and Discover : 
                      <span className="subhead ps-1">
                        
                        Buyers have access to an extensive range of vehicles from
                        various makes, models, and price ranges. Our user-friendly
                        search tools make finding the perfect car a breeze.
                      </span>
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Place Your Bid : 
                      <span className="subhead ps-1">
                        
                        Instead of a fixed price, buyers place bids on the cars
                        they're interested in. This adds an element of excitement
                        as the bidding progresses, creating a dynamic marketplace
                        where the value of each car is determined by its true
                        market demand.
                      </span>
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Engage and Compete : 
                      <span className="subhead ps-1">
                        
                        As the bidding heats up, buyers can engage in healthy
                        competition, outbidding others who share their interest in
                        the same vehicle.
                      </span>
                    </h5>
                  </li>
                  <li>
                    <h5 className="about_bot">
                      Winning the Bid : 
                      <span className="subhead ps-1">
                      
                        The buyer with the highest bid when the auction ends
                        emerges as the proud new owner of the car. It's a
                        thrilling victory that combines strategy, timing, and a
                        passion for cars.
                      </span>
                    </h5>
                  </li>
                </ol>
             
              
                <h5> Why Choose Cars2Cars : </h5>
                <ol>                 
                    <h5>
                    Transparency : 
                      <span className="subhead ps-1">
                        We're committed to transparency at every step. Buyers
                        can see the bidding history for each car, helping them
                        make informed decisions.
                      </span>
                    </h5>
                 
                  
                    <h5>
                      Fairness : 
                      <span className="subhead ps-1">
                        Our bidding process ensures that every car gets its fair
                        value based on its demand, condition, and
                        specifications.
                      </span>
                    </h5>
                 
                  
                    <h5>
                      Community : 
                      <span className="subhead ps-1">
                        Cars2Cars isn't just a platform; it's a community of car
                        enthusiasts. Connect with like-minded individuals who
                        share your passion for automobiles.
                      </span>
                    </h5>
                 
                  
                    <h5>
                      Convenience : 
                      <span className="subhead ps-1">
                        Say goodbye to the hassles of traditional car buying and
                        selling. Our platform is designed for convenience,
                        making the entire process efficient and user-friendly.
                      </span>
                    </h5>
                 
                  
                    <h5>
                      Trust : 
                      <span className="subhead ps-1">
                        Our secure payment and verification processes create a
                        trustworthy environment, providing peace of mind to both
                        buyers and sellers.
                      </span>
                    </h5>
                 
                </ol>
            
              
              <h5>Join Us in Redefining Car Transactions</h5>
              <p>Whether you're a seasoned car enthusiast or a first-time buyer, Cars2Cars welcomes you to experience the future of car trading. Join us in creating a vibrant community where cars are more than just machines – they're a part of our dreams, aspirations, and journeys. Start bidding, start connecting, and start a new chapter in the way you buy and sell cars with Cars2Cars.</p>
            
            
          </div>
        </div>
      </Container>
    </>
  );
};

export default About;
