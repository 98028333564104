import React, { useEffect, useState } from "react";
import { ApiPostMethod } from "../js/helper";
import constant from "../js/constant";
import { Breadcrumb } from "react-bootstrap";
// import Spinner from "react-bootstrap/Spinner";
import "./userProfile.css";


const PurchasePlan = () => {
    const userId = localStorage.hasOwnProperty("user")
        ? JSON.parse(localStorage.getItem("user"))
        : "";
    const [errorMessage, setErrorMessage] = useState(false);
    const [purchasePlanData, setPurchasePlanData] = useState([]);
    // const [isLoading,setIsLoading]=useState(false)

    const formData = new FormData();
    useEffect(() => {
        formData.append("userId", userId._id);
        let data = formData;
        let url = `${constant.baseurl}purchasePlan`;
        ApiPostMethod(url, data).then((response) => {
            if (response.error == false) {
                setErrorMessage(false);
                setPurchasePlanData(response.planHistory);
            } else {
                setErrorMessage(true);
            }
        });
    }, []);;


    return (
        <>
            <Breadcrumb className="bread_crumb">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>My Plans</Breadcrumb.Item>
            </Breadcrumb>

           

            {purchasePlanData.length > 0 ? (
                <ul className="list-unstyled">
                    <h4 style={{color:"#f70d02"}}>Active Plan Details!</h4>
                    {purchasePlanData.map((plan) => (
                        <li key={plan._id} className="d-flex justify-content-between activeplanlist">
                            <div className="col-3 text-center">
                                <h5>{plan.planName}</h5>
                                <h6 className="m-0">price: ₹ {plan.amount}</h6>
                            </div>
                            <div className="col-3 text-center">
                                <h5>Expires on</h5>
                                <h6 className="m-0">{new Date(plan.planExpire).toLocaleDateString('en-GB', { year: "numeric", month: "long", day: "numeric" })}</h6>
                            </div>
                            <div className="col-3 text-center">
                                <h5>For car</h5>
                                {/* {plan.brandName.map((brand, index) => (
                                    ))} */}
                                <h6 className="m-0">{plan.brandName[0]} - {plan.modelName[0]}</h6>
                            </div>
                            <div className="col-3 text-center">
                                <h5>City</h5>
                                <h6 className="m-0 text-uppercase">{plan.city[0]}</h6>
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No plans found.</p>
            )}

        </>
    );
};

export default PurchasePlan;
