// import React, { useCallback, useEffect, useState, useRef } from "react";
// import PropTypes from "prop-types";


// const MultiRangeSlider = ({ min, max, onChange }) => {
//   const [minVal, setMinVal] = useState(min);
//   const [maxVal, setMaxVal] = useState(max);
//   const minValRef = useRef(min);
//   const maxValRef = useRef(max);
//   const range = useRef(null);

//   const getPercent = useCallback(
//     (value) => Math.round(((value - min) / (max - min)) * 100),
//     [min, max]
//   );

//   useEffect(() => {
//     const minPercent = getPercent(minVal);
//     const maxPercent = getPercent(maxValRef.current);

//     if (range.current) {
//       range.current.style.left = `${minPercent}%`;
//       range.current.style.width = `${maxPercent - minPercent}%`;
//     }
//   }, [minVal, getPercent]);

//   useEffect(() => {
//     const minPercent = getPercent(minValRef.current);
//     const maxPercent = getPercent(maxVal);

//     if (range.current) {
//       range.current.style.width = `${maxPercent - minPercent}%`;
//     }
//   }, [maxVal, getPercent]);

//   useEffect(() => {
//     onChange({ min: minVal, max: maxVal });
//   }, [minVal, maxVal, onChange]);

//   return (
//     <div className="container d-flex justify-content-center">
//       <input
//         type="range"
//         min={min}
//         max={max}
//         value={minVal}
//         onChange={(event) => {
//           const value = Math.min(Number(event.target.value), maxVal - 1);
//           setMinVal(value);
//           minValRef.current = value;
//         }}
//         className="thumb thumb--left"
//         style={{ zIndex: minVal > max - 100 && "5" }}
//       />
//       <input
//         type="range"
//         min={min}
//         max={max}
//         value={maxVal}
//         onChange={(event) => {
//           const value = Math.max(Number(event.target.value), minVal + 1);
//           setMaxVal(value);
//           maxValRef.current = value;
//         }}
//         className="thumb thumb--right"
//       />

//       <div className="slider">
//         <div className="maximumtext">Maximum</div>
//         <div className="minimumtext">Minimum</div>
//         <div className="slider__track" />
//         <div ref={range} className="slider__range" />
//         <div className="slider__left-value">₹ {minVal}</div>
//         <div className="slider__right-value">₹ {maxVal}</div>
//       </div>
//     </div>
//   );
// };

// MultiRangeSlider.propTypes = {
//   min: PropTypes.number.isRequired,
//   max: PropTypes.number.isRequired,
//   onChange: PropTypes.func.isRequired
// };

// export default MultiRangeSlider;


import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import "./multiRangeSlider.css";

import 'rc-slider/assets/index.css';

const MultiRangeSlider = ({ minValue, maxValue, onChange, resetSlider }) => {
  const [sliderValues, setSliderValues] = useState([minValue, maxValue]);

  const handleSliderChange = (values) => {
    setSliderValues(values);
    const [minVal, maxVal] = values;
    onChange({ min: minVal, max: maxVal });
  };

  return (
    <div className="slider-container">
      <div className="values d-flex justify-content-between">
        <span>Minimum</span>
        <span>Maximum</span>
      </div>
      <Slider
        min={minValue}
        max={maxValue}
        value={sliderValues}
        onChange={handleSliderChange}
        range
      />
      <div className="values d-flex justify-content-between">
        <span>₹ {sliderValues[0].toLocaleString('en-IN')}</span>
        <span>₹ {sliderValues[1].toLocaleString('en-IN')}</span>
      </div>
    </div>
  );
};

export default MultiRangeSlider;

