import { useState } from 'react';
import constant from '../js/constant';
import { ApiPostMethod } from '../js/helper';
import { ToastContainer, toast } from 'react-toastify';
import "./button.css"
import { Spinner } from 'react-bootstrap';

function WishlistButton({uPostId, userId, wishlist, onWishlistClick}) {
  const formData = new FormData();
  const [isWishlist, setIsWishlist] = useState(wishlist);
  const [isloading, setIsLoading] = useState(false)

  const handleClick = async () => {
    setIsLoading(true);
    try {
        formData.append('carPostId', uPostId)
        formData.append('userId', userId)
        let data = formData
        let url = `${constant.baseurl}wishlist`;
        ApiPostMethod(url, data)
        .then((response) =>{
            if(response.error == false){
                setIsWishlist(!isWishlist);
                setIsLoading(false);
                if(window.location.pathname ==="/ShortListedCars"){
                    onWishlistClick();
                }
            }else{
            }
        })
    } catch (error) {
        setIsLoading(false);
        toast.info('Unable to add to whishlist!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
    }
  }

  return (
    <button className={(localStorage.hasOwnProperty('user')) ? "pb-2 wishlist-Btn" : "pb-2 wishlist-Btn d-none"} onClick={handleClick}>
        {isloading ? (
            <Spinner animation="border" variant="danger" style={{width:"1.5rem", height:"1.5rem"}}/>
        ) :
        ((isWishlist) 
        ?
        <svg width="25" height="25" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.3917 6.72289C29.6468 5.97769 28.7624 5.38654 27.7891 4.98322C26.8157 4.5799 25.7724 4.37231 24.7188 4.37231C23.6651 4.37231 22.6218 4.5799 21.6485 4.98322C20.6751 5.38654 19.7907 5.97769 19.0458 6.72289L17.5 8.26873L15.9542 6.72289C14.4496 5.21834 12.409 4.37309 10.2813 4.37309C8.1535 4.37309 6.1129 5.21834 4.60834 6.72289C3.10379 8.22744 2.25854 10.2681 2.25854 12.3958C2.25854 14.5236 3.10379 16.5642 4.60834 18.0687L6.15418 19.6146L17.5 30.9604L28.8458 19.6146L30.3917 18.0687C31.1369 17.3239 31.728 16.4395 32.1313 15.4661C32.5347 14.4927 32.7423 13.4494 32.7423 12.3958C32.7423 11.3422 32.5347 10.2989 32.1313 9.3255C31.728 8.35212 31.1369 7.46775 30.3917 6.72289Z" fill="#F70D02" stroke="#F70D02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        :
        <svg width="25" height="25" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.3919 6.72289C29.6471 5.97769 28.7627 5.38654 27.7893 4.98322C26.8159 4.5799 25.7726 4.37231 24.719 4.37231C23.6654 4.37231 22.6221 4.5799 21.6487 4.98322C20.6753 5.38654 19.7909 5.97769 19.0461 6.72289L17.5003 8.26873L15.9544 6.72289C14.4499 5.21834 12.4093 4.37309 10.2815 4.37309C8.15375 4.37309 6.11314 5.21834 4.60859 6.72289C3.10404 8.22744 2.25879 10.2681 2.25879 12.3958C2.25879 14.5236 3.10404 16.5642 4.60859 18.0687L6.15442 19.6146L17.5003 30.9604L28.8461 19.6146L30.3919 18.0687C31.1371 17.3239 31.7283 16.4395 32.1316 15.4661C32.5349 14.4927 32.7425 13.4494 32.7425 12.3958C32.7425 11.3422 32.5349 10.2989 32.1316 9.3255C31.7283 8.35212 31.1371 7.46775 30.3919 6.72289Z" fill="#FFFEFE" stroke="#D9D9D9" stroke-width="2.04723" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        )}
    </button>
  );
}

export default WishlistButton;
