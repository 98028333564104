import { useEffect, useState } from "react";
import { Container, Col, Modal } from "react-bootstrap";
import constant from "../js/constant";
import { ApiGetMethod } from "../js/helper";
import "bootstrap/dist/css/bootstrap.min.css";
import "./userProfile.css";

import LoginModal from "../login/loginmodal";
import Payment from "../js/payment";

const ViewPlansModel = ({ showAllPlan, closeAllPlan, carPostId, city }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleModalClose = () => {
    setShowLoginModal(false);
  };

  const handleModalShow = () => {
    setShowLoginModal(true);
  };

  const formdata = new FormData();
  const [viewAllPlans, setViewAllPLans] = useState([]);
  const [viewPunePlans, setViewPunePlans] = useState([]);
  const [viewOutsidePlans, setViewOutsidePlans] = useState([]);
  const userCity = localStorage.hasOwnProperty("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "";

  useEffect(() => {
    let data = formdata;
    let url = `${constant.baseurl}fetchPlan`;
    ApiGetMethod(url, data).then((response) => {
      if (response.error == false) {
        setViewAllPLans(response.plans);
        setViewPunePlans(
          response.plans.filter((plan) => plan.region === "Pune")
        );
        setViewOutsidePlans(
          response.plans.filter((plan) => plan.region === "Out Of Pune")
        );
      } else {
      }
    });
  }, []);

  return (
    <>
      <Modal show={showAllPlan} onHide={closeAllPlan} centered size="lg">
        <Modal.Body>
          <Container fluid>
            <Col
              lg="12"
              className="d-flex justify-content-between plans_wrapper"
              style={{ flexWrap: "wrap" }}
            >
              {city === "pune"
                ? viewPunePlans.slice().reverse().map((plans, index) => (
                  <Col lg="3" key={index} className="allPlans">
                    <div className="planhead d-flex justify-content-center">
                      <h4 className="head-text">{plans.name}</h4>
                    </div>
                    <ul className="planbenefits p-0">
                      <li className="d-flex align-items-center p-2">
                        <span className="me-2 svg_plans">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" stroke="#3ACD48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 4L12 14.01L9 11.01" stroke="#3ACD48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                        </span>
                        <h6 className="m-0">Add car in quick sell</h6>
                      </li>
                      <li className="d-flex align-items-center p-2">
                        <span className="me-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 4L12 14.01L9 11.01" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                        </span>
                        <h6 className="m-0">For {plans.days} day</h6>
                      </li>
                      <li className="d-flex align-items-center p-2">
                        <span className="me-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" stroke="#3ACD48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 4L12 14.01L9 11.01" stroke="#3ACD48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                        </span>
                        <h6 className="m-0">All Advance Filter</h6>
                      </li>
                      <li className="d-flex align-items-center p-2">
                        <span className="me-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" stroke="#3ACD48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 4L12 14.01L9 11.01" stroke="#3ACD48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                        </span>
                        <h6 className="m-0">
                           Verified Property 
                        </h6>
                      </li>
                    </ul>
                    <div className="Price">
                      <div className="offeredPrice d-flex justify-content-center">
                        <h6>₹ {plans.price}</h6>
                      </div>
                      <div className="discountPrice d-flex justify-content-center">
                        <h5 className="text-danger font-weight-bold">
                        ₹ {plans.offerPrice}
                        </h5>
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                      {localStorage.hasOwnProperty("user") ? (
                        plans.price != 0 ? (
                          <Payment
                            planId={plans._id}
                            planName={plans.name}
                            days={plans.days}
                            planExpire={plans.planExpire}
                            amount={plans.price}
                            region={plans.region}
                            carPostId={carPostId}
                          />
                        ) : (
                          <button
                            className="btn-plan"
                            onClick={() => alert("use referal")}
                          >
                            Activate
                          </button>
                        )
                      ) : (
                        <>
                          <button
                            className="col-10 btn-plan"
                            onClick={handleModalShow}
                          >
                            Activate
                          </button>
                          <LoginModal
                            show={showLoginModal}
                            handleClose={handleModalClose}
                          />
                        </>
                      )}
                    </div>
                  </Col>
                ))
                : viewOutsidePlans.slice().reverse().map((plans, index) => (
                    <Col lg="3" key={index} className="allPlans">
                      <div className="planhead d-flex justify-content-center">
                        <h4 className="head-text">{plans.name}</h4>
                      </div>
                      <ul className="planbenefits p-0">
                        <li className="d-flex align-items-center p-2">
                          <span className="me-2">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" stroke="#3ACD48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 4L12 14.01L9 11.01" stroke="#3ACD48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                          </span>
                          <h6 className="m-0">Add car in quick sell</h6>
                        </li>
                        <li className="d-flex align-items-center p-2">
                          <span className="me-2">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" stroke="#3ACD48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 4L12 14.01L9 11.01" stroke="#3ACD48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                          </span>
                          <h6 className="m-0">For {plans.days} day</h6>
                        </li>
                        <li className="d-flex align-items-center p-2">
                          <span className="me-2">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" stroke="#3ACD48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 4L12 14.01L9 11.01" stroke="#3ACD48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                          </span>
                          <h6 className="m-0">All Advance Filter</h6>
                        </li>
                        <li className="d-flex align-items-center p-2">
                          <span className="me-2">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" stroke="#3ACD48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 4L12 14.01L9 11.01" stroke="#3ACD48" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                          </span>
                          <h6 className="m-0">
                             Verified Property 
                          </h6>
                        </li>
                      </ul>
                      <div className="Price">
                        <div className="offeredPrice d-flex justify-content-center">
                        <h6> ₹ {plans.price}</h6>
                       
                        </div>
                        <div className="discountPrice d-flex justify-content-center">
                          <h5 className="text-danger font-weight-bold">
                          ₹ {plans.offerPrice}
                          </h5>
                        </div>
                      </div>
                      <div className="col-12 d-flex justify-content-center">
                        {localStorage.hasOwnProperty("user") ? (
                          plans.price != 0 ? (
                            <Payment
                              planId={plans._id}
                              planName={plans.name}
                              days={plans.days}
                              planExpire={plans.planExpire}
                              amount={plans.price}
                              region={plans.region}
                              carPostId={carPostId}
                            />
                          ) : (
                            <button
                              className="btn-plan"
                              onClick={() => alert("use referal")}
                            >
                              Activate
                            </button>
                          )
                        ) : (
                          <>
                            <button
                              className="col-10 btn-plan"
                              onClick={handleModalShow}
                            >
                              Activate
                            </button>
                            <LoginModal
                              show={showLoginModal}
                              handleClose={handleModalClose}
                            />
                          </>
                        )}
                      </div>
                    </Col>
                  ))}
            </Col>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ViewPlansModel;
