import blog1 from "../image/blog1.png";
import blog2 from "../image/blog2.png";
import blog3 from "../image/blog3.png";
import mainblog from "../image/mainblog.png";
import avtar from "../image/avatar.png";
import buycar1 from "../image/buycar-tab1.png";
import buycar2 from "../image/buycar-tab2.png";
import buycar3 from "../image/buycar-tab3.png";
import buycar4 from "../image/buycar-tab4.png";
import sellcar1 from "../image/sellcar-tab1.png";
import sellcar2 from "../image/sellcar-tab2.png";
import sellcar3 from "../image/sellcar-tab3.png";
import sellcar4 from "../image/sellcar-tab4.png";

export const blogData = [
  {
    Image: blog1,
    Text: "“Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.”",
    avatar: avtar,
    avtartext: "Jenny Wilson  |  Pune",
    bottom: "Maruti Suzuki 2021 Baleno Alpha ",
  },
  {
    Image: blog2,
    Text: "“Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.”",
    avatar: avtar,
    avtartext: "Jenny Wilson  |  Pune",
    bottom: "Maruti Suzuki 2021 Baleno Alpha ",
  },
  {
    Image: blog3,
    Text: "“Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.”",
    avatar: avtar,
    avtartext: "Jenny Wilson  |  Pune",
    bottom: "Maruti Suzuki 2021 Baleno Alpha ",
  },
  {
    Image: blog1,
    Text: "“Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.”",
    avatar: avtar,
    avtartext: "Jenny Wilson  |  Pune",
    bottom: "Maruti Suzuki 2021 Baleno Alpha ",
  },
  {
    Image: blog2,
    Text: "“Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.”",
    avatar: avtar,
    avtartext: "Jenny Wilson  |  Pune",
    bottom: "Maruti Suzuki 2021 Baleno Alpha ",
  },
  {
    Image: blog3,
    Text: "“Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.”",
    avatar: avtar,
    avtartext: "Jenny Wilson  |  Pune",
    bottom: "Maruti Suzuki 2021 Baleno Alpha ",
  },
  {
    Image: blog1,
    Text: "“Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.”",
    avatar: avtar,
    avtartext: "Jenny Wilson  |  Pune",
    bottom: "Maruti Suzuki 2021 Baleno Alpha ",
  },
  {
    Image: blog2,
    Text: "“Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.”",
    avatar: avtar,
    avtartext: "Jenny Wilson  |  Pune",
    bottom: "Maruti Suzuki 2021 Baleno Alpha ",
  },
  {
    Image: blog3,
    Text: "“Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.”",
    avatar: avtar,
    avtartext: "Jenny Wilson  |  Pune",
    bottom: "Maruti Suzuki 2021 Baleno Alpha ",
  },
];

export const FaqData = [
  {
    Quetion: "Why should I sell my used car to Cars 2 Cars ?",
    Answer:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as oppo English.",
  },
  {
    Quetion: "How long will it take for the payment to be transferred ?",
    Answer:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as oppo English.",
  },
  {
    Quetion: "How much time will you take for my car’s inspection ?",
    Answer:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as oppo English.",
  },
  {
    Quetion: "Can I reschedule my appointment ? ",
    Answer:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as oppo English.",
  },
  {
    Quetion: "Do you provide home inspection ?",
    Answer:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as oppo English.",
  },
  {
    Quetion: "I also want to buy another car? What should I do ?",
    Answer:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as oppo English.",
  },
  {
    Quetion: "Can you notify me when you have a good offer ?",
    Answer:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as oppo English.",
  },
  {
    Quetion: "Why should I sell my used car to Cars 2 Cars ?",
    Answer:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as oppo English.",
  },
];

export const mainblogData = [
  {
    Image: mainblog,
    Title: "Blog Title",
    Text: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate ",
    Date: "24 feb 2023 , 10 min",
  },
  {
    Image: mainblog,
    Title: "Blog Title Blog Title Blog Title",
    Text: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate, Lorem ipsum is a placeholder text commonly used to demonstrate ",
    Date: "24 feb 2023 , 10 min",
  },
  {
    Image: mainblog,
    Title: "Blog Title",
    Text: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate ",
    Date: "24 feb 2023 , 10 min",
  },
  {
    Image: mainblog,
    Title: "Blog Title",
    Text: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate ",
    Date: "24 feb 2023 , 10 min",
  },
  {
    Image: mainblog,
    Title: "Blog Title",
    Text: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate ",
    Date: "24 feb 2023 , 10 min",
  },
  {
    Image: mainblog,
    Title: "Blog Title",
    Text: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate ",
    Date: "24 feb 2023 , 10 min",
  },
  {
    Image: mainblog,
    Title: "Blog Title",
    Text: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate ",
    Date: "24 feb 2023 , 10 min",
  },
  {
    Image: mainblog,
    Title: "Blog Title",
    Text: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate ",
    Date: "24 feb 2023 , 10 min",
  },
  {
    Image: mainblog,
    Title: "Blog Title",
    Text: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate ",
    Date: "24 feb 2023 , 10 min",
  },
];

export const BuyCar = [
  {
    image: buycar1,
    title: "1. Find the perfect car",
    text: "Seamlessly browse thousands of MRL Certified cars",
  },
  {
    image: buycar2,
    title: "2. Make Offer",
    text: "Enter your offer price today and unlock savings",
  },
  {
    image: buycar3,
    title: "3. Test Drive",
    text: "We'll bring the car to you, or you can visit a Cars 2 Cars hub for free",
  },
  {
    image: buycar4,
    title: "4. Buy it your way",
    text: "Done your payment and get doorstep delivery",
  },
];

export const SellCar = [
  {
    image: sellcar1,
    title: "1. Enter your Car Details",
    text: "Gone are the days of spending weeks to sell a car. Sell your car in minutes from home.",
  },
  {
    image: sellcar2,
    title: "2. Get inspected price",
    text: "The offer that we make based on the car details you fill will be final and binding.",
  },
  {
    image: sellcar3,
    title: "3. Post car",
    text: "The offer that we make based on the car details you fill will be final and binding.",
  },
  {
    image: sellcar4,
    title: "4. Get a Final Offer, instantly! ",
    text: "We will verify the car details at your home, pick up the car and transfer the amount.",
  },
];
