import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import constant from "../js/constant";
import { ApiPostMethod } from "../js/helper";
import Login_Logo from "./Cars2Cars_Logo.png";
import "./register.css";
import LoginModal from "../login/loginmodal";
import { validate } from "../validator/validate";
import { Modal, ModalBody } from "react-bootstrap";
import { useEffect } from "react";

const Registermodal = ({ registerShow, registerClose, referId, mobileNumber }) => {
  const [mobNo, setMobNo] = useState("");
  useEffect(()=>{
    // console.log("mobileNumber", mobileNumber);
    setMobNo(mobileNumber)
    setregisteredNo(mobileNumber)
  },[mobileNumber])
  const [name, setName] = useState("");
  const [registeredNo, setregisteredNo] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [refer, setRefer] = useState(referId ? referId : "");
  const [errorStatus, setErrorStatus] = useState(false);
  const [emailError, setEmailError] = useState("");
  
  const [error, setError] = useState({
    name: "",
    mobile: "",
    email: "",
    address: "",
    city: "",
  });
  const [showLoginModal, setShowLoginModal] = useState(false);

  function handleError(e) {
    const { name, value } = e.target;

    setError((prevData) => ({
      ...prevData,
      [name]: validate(name, value),
    }));
  }

  const navigate = useNavigate();

  // const handleMonNo = (e) => {
  //   handleError(e);
  //   setMobNo(e.target.value);
  //   setregisteredNo(e.target.value);
  // };

  const handleMonNo = (e) => {
    const { name, value } = e.target;
    const mobileRegex = /^[6-9]\d{9}$/; // Regex for valid mobile numbers

    if (value && !value.match(mobileRegex)) {
      setError((prevData) => ({
        ...prevData,
        [name]: 'Invalid mobile number formate',
      }));
    } else {
      setError((prevData) => ({
        ...prevData,
        [name]: '',
      }));
    }
    
    setMobNo(value);
    setregisteredNo(value);
  };
  const handleName = (e) => {
    handleError(e);
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    handleError(e);
    setEmail(e.target.value);

    setEmailError("");
  };
  const handleAddress = (e) => {
    handleError(e);
    setAddress(e.target.value);
  };
  const handleCity = (e) => {
    handleError(e);
    setCity(e.target.value);
  };
  const handleRefer = (e) => {
    setRefer(e.target.value);
  };

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("mobile", mobNo);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("referedBy", refer);
    
    let data = formData;
    let url = `${constant.baseurl}registerUser`;
    ApiPostMethod(url, data).then((response) => {
      if (response.error) {
        toast.error(response.message, {
          position: "bottom-right",
          autoClose: 1000,
          closeOnClick:true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.success(response.message, {
          position: "bottom-right",
          autoClose: 1500,
          draggable: true,
          closeOnClick:true,
          progress: undefined,
          theme: "light",
          // onClose: () => {
          //   handleModalopenClose();
          // },
        });
        handleModalopenClose();
      }
    });
  }

  const handleModalopenClose = () => {
    setMobNo("");
    setName("");
    setEmail("");
    setAddress('');
    setCity('');
    setRefer("");
    registerClose();
    setShowLoginModal(true);
  };
  const handleregister = () => {
    setMobNo("");
    setName("");
    setEmail("");
    setAddress('');
    setCity('');
    registerClose();
  }
  return (
    <>
      <Modal show={registerShow} onHide={handleregister} centered>
        <Modal.Body>
          <section className="register">
            <div className="container-fluid d-flex h-100">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <div className="col-12">
                  <div className="register-logo">
                    <img src={Login_Logo} alt="" />
                  </div>
                  <form>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter Number  *"
                        name="mobile"
                        onChange={handleMonNo}
                        value={mobNo}
                      />
                      {error.mobile && (
                        <p className="text-danger mb-1">{error.mobile}</p>
                      )}
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter Name  *"
                        name="name"
                        onChange={handleName}
                        value={name}
                      />
                      {error.name && (
                        <p className="text-danger mb-1">{error.name}</p>
                      )}
                      <input
                        type="email"
                        className="form-control mb-2"
                        placeholder="Enter Email  *"
                        name="email"
                        onChange={handleEmail}
                        value={email}
                      />
                      
                      {error.email && (
                        <p className="text-danger mb-1">{error.email}</p>
                      )}
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter Address  *"
                        name="address"
                        onChange={handleAddress}
                        value={address}
                      />
                      {error.address && (
                        <p className="text-danger mb-1">{error.address}</p>
                      )}
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter City  *"
                        name="city"
                        onChange={handleCity}
                        value={city}
                      />
                      {error.city && (
                        <p className="text-danger mb-1">{error.city}</p>
                      )}
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter Refer Number"
                        name="refer"
                        onChange={handleRefer}
                        value={refer}
                      />
                      {error.refer && (
                        <p className="text-danger mb-1">{error.refer}</p>
                      )}
                    </div>
                    <div className="register-btn text-center">
                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn-theme"
                      >
                        Register
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
      <ToastContainer />
      {showLoginModal && (
        <LoginModal
          show={showLoginModal}
          handleClose={() => setShowLoginModal(false)}
          mobileNo={registeredNo}
        />
      )}
    </>
  );
};
export default Registermodal;
