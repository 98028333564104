import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image } from "react-bootstrap";
import singleblogimg from "../image/singleblog.png";
import singleblogavatar from "../image/singleblog_avatar.png";
import "./singleblog.css";
import { json, useLocation } from "react-router-dom";
import { ApiPostMethod } from "./js/helper";
import constant from "./js/constant";
import LoginModal from "./login/loginmodal";
import Swal from "sweetalert2";
import {FaCheck } from "react-icons/fa";
import {FaLocationArrow } from "react-icons/fa";

const SingleBlog = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleModalClose = () => {
    setShowLoginModal(false);
  };

  const handleModalShow = () => {
    setShowLoginModal(true);
  };

  const location = useLocation();
  const [userName, setuserName] = useState(
    localStorage.hasOwnProperty("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "User"
  );
  const formData = new FormData();
  const query_Params = new URLSearchParams(location.search);
  const blogId = query_Params.get("blogId");

  const [blogs, setBlogs] = useState([]);
  const [blogsTime, setBlogsTime] = useState();
  const [Blogscomments, setBlogscomments] = useState([]);

  useEffect(() => {
    formData.append("blogid", blogId);
    let url = `${constant.baseurl}viewSingleblogs`;
    let data = formData;
    ApiPostMethod(url, data).then((response) => {
      if (response.error == false) {
        // console.log(response);
        setBlogs(response.Blogs[0]);
        setBlogscomments(response.Blogs[0].Blogscomments);
        const date = new Date(response.Blogs[0].updatedAt);
        const options = { year: "numeric", month: "long", day: "numeric" };
        const formattedDate = date.toLocaleDateString("en-US", options);
        const currentDate = new Date();
        const timeDifference = Math.abs(currentDate - date);
        const daysDifference = Math.floor(
          timeDifference / (1000 * 60 * 60 * 24)
        );
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

        let formattedTimeDifference;
        if (daysDifference > 0) {
          formattedTimeDifference = `${daysDifference} days ago`;
        } else if (hoursDifference > 0) {
          formattedTimeDifference = `${hoursDifference} hr ago`;
        } else {
          formattedTimeDifference = "just now";
        }
        const finalResult = `${formattedDate} | ${formattedTimeDifference}`;
        setBlogsTime(finalResult);
      } else {
        // console.log(response);
      }
    });
  }, []);

  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    if (localStorage.hasOwnProperty("user")) {
      // console.log(inputValue.trim());
      if (inputValue.trim() != "") {
        setErrorMessage(false);
        formData.append("blogId", blogId);
        formData.append("userName", userName.name ? userName.name : userName);
        formData.append("Comment", inputValue);
        let url = `${constant.baseurl}addComment`;
        let data = formData;
        // console.log("data", data);
        ApiPostMethod(url, data).then((response) => {
          if (response.error === false) {
            // console.log(response);
            setInputValue("");
            Swal.fire({
              icon: "success",
              title: "Comment Added",
              text: "Your comment has been added successfully!",
            });
          } else {
            // console.log(response);
          }
        });
      } else {
        setErrorMessage(true);
      }
    } else {
      handleModalShow();
    }
  };

  return (
    <>
      <Container fluid className="p-3 top_class" style={{ background: " #F5F5F5" }}>
        <div>
          <h4 className="single_Blog_heading">{blogs.title}</h4>
          <p className="sb_head_para">{blogsTime}</p>
          {/* <Col lg="12" className="single_blog_banner"
           
          > */}
            <Image className="single_blog_img"
              src={blogs.blogImage}
            />
          {/* </Col> */}
          <div className="col-10 para_wrapper comment-block">
            <div className="bottom_para">
              <p>{blogs.description}</p>
            </div>

            <div className="bottom bottom_para11 col-12 flex-wrap">
              {Blogscomments.map((Blogscomments, i) =>
                Blogscomments.status === 1 ? (
                  <div
                    className="col-12 d-flex align-self-start flex-wrap mb-3"
                    style={{
                      alignItems: "flex-start",
                      borderBottom: "1px solid darkgray",
                    }}
                    key={i}
                  >
                    <img
                      src={singleblogavatar}
                      style={{ height: "35px", width: "35px" }}
                    />
                    <div className="bottom_right">
                      <h6 style={{ fontWeight: 600 }}>
                        {Blogscomments.userName}
                      </h6>
                      <p className="mb-1">{Blogscomments.Comment}</p>
                      <p className="mb-2">
                        {`${new Date(Blogscomments.createdDate).toLocaleString(
                          "en-US",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                          }
                        )}`}
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
            </div>

            <div className="bottom bottom_para col-12">
              <div>
                <img  style={{ height: "35px", width: "35px" }} src={singleblogavatar} />
              </div>
              <div className="bottom_right col-11">
                <h6 style={{ fontWeight: 600 }}>
                  {userName.name ? userName.name : userName}
                </h6>
                <div className="d-flex flex-wrap">
                  <Form.Control
                    style={{ width: "80%", margin: "0",border:"0",background:"#F5F5F5", borderBottom:"1px solid black "}}
                    type="text"
                    placeholder="Type your comment"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    className="submit-button"
                    onClick={handleSubmit}
                  >
                    <FaLocationArrow />
                   {/* <FaCheck /> */}
                  </button>
                </div>
                {errorMessage === true ? <p>Enter Comment</p> : ""}
                <LoginModal
                  show={showLoginModal}
                  handleClose={handleModalClose}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SingleBlog;
