import { useState, useEffect } from "react";
import "./login.css";
import Login_Logo from "./Cars2Cars_Logo.png";
import constant from "../js/constant";
import { ApiPostMethod } from "../js/helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Login() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [showOtp, setShowOtp] = useState();
  const [otp, setOtp] = useState("");
  const [showSendOtp, setShowSendOtp] = useState(true);
  const [showRegister, setShowRegister] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  const [showResendOtpButton, setShowResendOtpButton] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [adminInfo, setAdminInfo] = useState({});
  const [mobileNumberDisabled, setMobileNumberDisabled] = useState(false);
  const [showingToast, setShowingToast] = useState(false);
  const navigate = useNavigate();

  const handleSendOtp = async () => {
    var OtpNumber = document.getElementById("mobile-number");
    // alert(OtpNumber.value.length);
    if (OtpNumber.value == "") {
      setErrorMessage("Please Enter Mobile Number.");
    } else {
      if (OtpNumber.value.length < 10 || OtpNumber.value.length > 10) {
        setErrorMessage("Please Enter Valid Mobile Number.");
      } else {
        try {
          const formData = new FormData();
          formData.append("mobile", mobileNumber);
          let data = formData;
          let url = `${constant.baseurl}getOtp`;

          ApiPostMethod(url, data).then((response) => {
            if (response.error == false) {
              setShowOtp(response.message);
              setErrorMessage("");
              setShowOtpField(true);
              setShowResendOtpButton(true);
              setShowLoginButton(true);
              setShowSendOtp(false);
              setMobileNumberDisabled(true);
              setShowRegister(false);
            } else {
              if (response.time) {
                setShowOtpField(false);
                setShowResendOtpButton(false);
                setShowLoginButton(false);
                setErrorMessage("Login attempt crossed.");
              } else {
                setShowOtpField(false);
                setShowResendOtpButton(false);
                setShowLoginButton(false);
                setErrorMessage(response.message);
                setAdminInfo(response.Admin ? response.Admin : {})
                setShowRegister(response.Admin ? false : true);
                setShowSendOtp(response.Admin ? false : true);
              }
            }
          });
        } catch (error) {
          setErrorMessage("Error sending OTP. Please try again.");
        }
      }
    }
  };
  const handleResendOtp = async () => {
    try {
      const formData = new FormData();
      formData.append("mobile", mobileNumber);
      let data = formData;
      let url = `${constant.baseurl}getOtp`;
      ApiPostMethod(url, data).then((response) => {
        if (response.error == false) {
          setOtp("");
          setErrorMessage("");
        } else {
          if (response.time) {
            setErrorMessage("Otp Attempts Crossed.");
          } else {
            setErrorMessage("Error resending OTP.");
          }
        }
      });
    } catch (error) {
      setErrorMessage("Error resending OTP. Please try again.");
    }
  };

  const handleLogin = async () => {
    try {
      const formData = new FormData();
      formData.append("mobile", mobileNumber);
      formData.append("otp", otp);
      let data = formData;
      let url = `${constant.baseurl}login`;
      ApiPostMethod(url, data).then((response) => {
        if (response.error == false) {
          localStorage.setItem("token", response.token);
          localStorage.setItem("user", JSON.stringify(response.user));
          setErrorMessage("");
          setShowingToast(true);
          toast.success("User Logged in Successfully", {
            position: "top-center",
            autoClose: 3000,
            theme: "light",
            onClose: () => {
              // setShowingToast(false);
              navigate("/index");
            },
          });
        } else {
          setErrorMessage("Invalid OTP. Please try again.");
        }
      });
    } catch (error) {
      setErrorMessage("Error logging in. Please try again.");
    }
  };

  useEffect(() => {
    if (showingToast) {
      setTimeout(() => {
        setShowingToast(false);
      }, 3000);
    }
  }, [showingToast]);

  return (
    <>
      <section className="login-modal" id="login_modal">
        <div className="container-fluid d-flex h-100">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <div className="col-4 login-form">
              <div className="login-logo">
                <img src={Login_Logo} alt="" />
              </div>
              <form>
                {/* <label htmlFor="mobile-number">Mobile Number:</label> */}
                <input
                  type="tel"
                  className="form-control mb-2"
                  id="mobile-number"
                  name="mobile-number"
                  value={mobileNumber}
                  onChange={(event) => setMobileNumber(event.target.value)}
                  disabled={mobileNumberDisabled}
                  placeholder="Enter your mobile no."
                />
                <div className="showotp">
                  <span> OTP : </span>
                  <span id="C2COtp">
                    {showOtp ? showOtp.match(/\d+/g)[0] : ""}
                  </span>
                </div>
                {showOtpField && (
                  <div className="col-12 d-flex">
                    <div className="col-8">
                      <input
                        className="form-control"
                        type="number"
                        id="otp"
                        name="otp"
                        value={otp}
                        onChange={(event) => setOtp(event.target.value)}
                        placeholder="OTP"
                      />
                    </div>
                    <div className="col-4">
                      {showResendOtpButton && (
                        <button
                          className="btn btn-theme btn_resend"
                          type="button"
                          onClick={handleResendOtp}
                        >
                          Resend OTP
                        </button>
                      )}
                    </div>
                  </div>
                )}

                {errorMessage && <p>{errorMessage}</p>}
                {adminInfo && (
                  <p>
                    Connect with us using "<a className="text-decoration-none" href={`tel:${adminInfo.number}`}>{adminInfo.number}</a>" or drop a mail at "<a className="text-decoration-none"  href={`mailto:${adminInfo.email}`}>{adminInfo.email}</a>".
                  </p>
                )}
                <div className="btn-block">
                  {showSendOtp && (
                    <button
                      className="btn btn-theme sendOtp"
                      type="button"
                      onClick={handleSendOtp}
                    >
                      Send OTP
                    </button>
                  )}
                  {showRegister && (
                    <NavLink to="/Register">
                      <button className="btn btn-theme" type="button">
                        Register
                      </button>
                    </NavLink>
                  )}
                  {showLoginButton && (
                    <button
                      className="btn btn-theme"
                      type="button"
                      onClick={handleLogin}
                    >
                      Login
                    </button>
                  )}
                  {showingToast && <ToastContainer />}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
