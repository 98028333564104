import logo from "./logo.svg";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/navbar/layout";
import CarPost from "./components/carpost/carPost.js";
import About from "./components/about";
import Blog from "./components/blog";
import Login from "./components/login/login";
import Register from "./components/register/register";
import Index from "./components/index/index";
import Appoinment from "./components/appoinment/appoinment";
import CarSearch from "./components/searchCars/carSearch";
import UserProfilePage from "./components/userProfile/userProfilePage";
import "./App.css";
import carousal from "./components/carousal";
import Footer from "./components/footer/footer";
import LuxuryCarSearch from "./components/searchCars/luxuryCarSearch";
import SingleCarDetail from "./components/singlecarDetails/singleCarDetail";
import ShortListedCars from "./components/sharemodal/shortlistedCars";
import ContactUs from "./components/contactUs/contactUs";
import PrivacyPolicy from "./components/staticPages/privacyPolicy";
import TermsAndCondition from "./components/staticPages/terms&Condition";
import HelpandSupport from "./components/userProfile/helpAndSupport";
import SingleBlock from "./components/singleBlog";
import SingleBlog from "./components/singleBlog";
import BottomNavbar from "./components/navbar/bottomNavBar";
import ViewPlansModel from "./components/userProfile/viewPlansModal";
import Help from "./components/staticPages/help";
import CarLoanCalculator from "./components/navbar/emiCalculator";

function App() {
  return (
    <>
        <Layout />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="About" element={<About />} />
          <Route path="Carpost" element={<CarPost />} />
          <Route path="Blog" element={<Blog />} />

          <Route path="singleBlogDescription" element={<SingleBlog />} />
          <Route path="Login" element={<Login />} />
          <Route path="Register" element={<Register />} />
          <Route path="Index" element={<Index />} />
          <Route path="Appoinment" element={<Appoinment />} />
          <Route path="CarSearch" element={<CarSearch />} />
          <Route path="LuxuryCarSearch" element={<LuxuryCarSearch />} />
          <Route path="UserProfilePage" element={<UserProfilePage />} />
          <Route path="SingleCarDetail" element={<SingleCarDetail />} />
          <Route path="ShortListedCars" element={<ShortListedCars />} />
          <Route path="ViewPlansModel" element={<ViewPlansModel />} />
          <Route path="ContactUs" element={<ContactUs />} />
          <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="HelpandSupport" element={<HelpandSupport />} />
          <Route path="TermsAndCondition" element={<TermsAndCondition />} />
          <Route path="help" element={<Help />} />
          <Route path="carLoanCalculator" element={<CarLoanCalculator />} />
        </Routes>

        <Footer />
        <BottomNavbar />
    </>
  );
}
export default App;
