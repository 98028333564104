import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import "./userProfile.css";
import HelpandSupport from "./helpAndSupport";
import { useLocation, useNavigate } from "react-router-dom";
import PersonalInformation from "./personelInformation";
import UserPostCar from "./userCarPost";
import UserBooking from "./userBooking";
import ViewPlans from "./viewPlans";
import PurchasePlan from "./purchasePlan";


const UserProfilePage = () => {
  let navigate = useNavigate();
  const [userName, setUserName] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTabKeyFromUrl = queryParams.get("activeTabKey");

  const [activeTabKey, setActiveTabKey] = useState(
    activeTabKeyFromUrl || localStorage.getItem("activeTabKey") || "booking"
  );

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (activeTabKeyFromUrl) {
      setActiveTabKey(activeTabKeyFromUrl);
      localStorage.setItem("activeTabKey", activeTabKeyFromUrl);
    }
  }, [activeTabKeyFromUrl]);
  useEffect(() => {
    const nameUser = localStorage.hasOwnProperty("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    setUserName(nameUser.name);
  }, []);

  function removeDataFromLocalStorage() {
    localStorage.clear();
    // navigate("/");
    window.location.href = "/";
  }

  const handleTabSelect = (key) => {
    setActiveTabKey(key);
    localStorage.setItem("activeTabKey", key);
  };

  useEffect(() => {
    const newUrl = `#/UserProfilePage?activeTabKey=${activeTabKey}`;
    window.history.pushState({ activeTabKey }, "", newUrl);
  }, [activeTabKey]);

  const handlePopState = (event) => {
    const newTabKey = event.state ? event.state.activeTabKey : "booking";
    setActiveTabKey(newTabKey);
  };



  return (
    <>
      <Container fluid className="userProfilePage top_class">
        <Col lg="12" className="profile-section d-flex ">
          <Tab.Container
            id="left-tabs-example"
            activeKey={activeTabKey}
            onSelect={handleTabSelect}
          >
            <Container
              fluid
              className="col-lg-12 d-flex justify-content-between pt-4 pb-4"
            >
              <Col sm={3} className="profileNavlink ">
                <Nav variant="pills" className="flex-column">
                  <Col lg="12" className="user_detail text-center profile-data">
                    <Col lg="4" className="mx-auto">
                      <svg
                        viewBox="0 0 256 256"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect fill="none" height="100" width="100" />
                        <circle
                          cx="128"
                          cy="128"
                          fill="none"
                          r="96"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="8"
                        />
                        <circle
                          cx="128"
                          cy="120"
                          fill="none"
                          r="40"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="8"
                        />
                        <path
                          d="M63.8,199.4a72,72,0,0,1,128.4,0"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="8"
                        />
                      </svg>
                    </Col>
                    <h5>{userName}</h5>
                  </Col>
                  <Nav.Item>
                    <Nav.Link
                      className="profile-data d-flex justify-content-between"
                      eventKey="booking"
                    >
                      <span>
                        <svg
                          className="me-3"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14 2V8H20"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16 13H8"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16 17H8"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 9H9H8"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Bookings
                      </span>
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 18L15 12L9 6"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className=" profile-data d-flex justify-content-between"
                      eventKey="carPost"
                    >
                      <span>
                        <svg
                          className="me-3"
                          fill="none"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.7993 3C17.2899 3 18.5894 4.01393 18.9518 5.45974L19.337 7H20.25C20.6297 7 20.9435 7.28215 20.9932 7.64823L21 7.75C21 8.1297 20.7178 8.44349 20.3518 8.49315L20.25 8.5H19.714L19.922 9.3265C20.5708 9.72128 21.0041 10.435 21.0041 11.25V19.7468C21.0041 20.7133 20.2206 21.4968 19.2541 21.4968H17.75C16.7835 21.4968 16 20.7133 16 19.7468L15.999 18.5H8.004L8.00408 19.7468C8.00408 20.7133 7.22058 21.4968 6.25408 21.4968H4.75C3.7835 21.4968 3 20.7133 3 19.7468V11.25C3 10.4352 3.43316 9.72148 4.08177 9.32666L4.289 8.5H3.75C3.3703 8.5 3.05651 8.21785 3.00685 7.85177L3 7.75C3 7.3703 3.28215 7.05651 3.64823 7.00685L3.75 7H4.663L5.04898 5.46176C5.41068 4.01497 6.71062 3 8.20194 3H15.7993ZM6.504 18.5H4.499L4.5 19.7468C4.5 19.8848 4.61193 19.9968 4.75 19.9968H6.25408C6.39215 19.9968 6.50408 19.8848 6.50408 19.7468L6.504 18.5ZM19.504 18.5H17.499L17.5 19.7468C17.5 19.8848 17.6119 19.9968 17.75 19.9968H19.2541C19.3922 19.9968 19.5041 19.8848 19.5041 19.7468L19.504 18.5ZM18.7541 10.5H5.25C4.83579 10.5 4.5 10.8358 4.5 11.25V17H19.5041V11.25C19.5041 10.8358 19.1683 10.5 18.7541 10.5ZM10.249 14H13.7507C14.165 14 14.5007 14.3358 14.5007 14.75C14.5007 15.1297 14.2186 15.4435 13.8525 15.4932L13.7507 15.5H10.249C9.83478 15.5 9.49899 15.1642 9.49899 14.75C9.49899 14.3703 9.78115 14.0565 10.1472 14.0068L10.249 14H13.7507H10.249ZM17 12C17.5522 12 17.9999 12.4477 17.9999 13C17.9999 13.5522 17.5522 13.9999 17 13.9999C16.4477 13.9999 16 13.5522 16 13C16 12.4477 16.4477 12 17 12ZM6.99997 12C7.55225 12 7.99995 12.4477 7.99995 13C7.99995 13.5522 7.55225 13.9999 6.99997 13.9999C6.4477 13.9999 6 13.5522 6 13C6 12.4477 6.4477 12 6.99997 12ZM15.7993 4.5H8.20194C7.39892 4.5 6.69895 5.04652 6.50419 5.82556L5.71058 9H18.2929L17.4968 5.82448C17.3017 5.04596 16.6019 4.5 15.7993 4.5Z"
                            fill="#212121"
                          />
                        </svg>
                        My Posts
                      </span>
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 18L15 12L9 6"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className=" profile-data d-flex justify-content-between"
                      eventKey="myPlan"
                    >
                      <span>
                        <svg
                         className="me-3"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.5028 4.62704L5.5 6.75V17.2542C5.5 19.0491 6.95507 20.5042 8.75 20.5042L17.3663 20.5045C17.0573 21.3782 16.224 22.0042 15.2444 22.0042H8.75C6.12665 22.0042 4 19.8776 4 17.2542V6.75C4 5.76929 4.62745 4.93512 5.5028 4.62704ZM17.75 2C18.9926 2 20 3.00736 20 4.25V17.25C20 18.4926 18.9926 19.5 17.75 19.5H8.75C7.50736 19.5 6.5 18.4926 6.5 17.25V4.25C6.5 3.00736 7.50736 2 8.75 2H17.75ZM17.75 3.5H8.75C8.33579 3.5 8 3.83579 8 4.25V17.25C8 17.6642 8.33579 18 8.75 18H17.75C18.1642 18 18.5 17.6642 18.5 17.25V4.25C18.5 3.83579 18.1642 3.5 17.75 3.5Z"
                            fill="#212121"
                          />
                        </svg>
                        My Plans
                      </span>
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 18L15 12L9 6"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className=" profile-data d-flex justify-content-between"
                      eventKey="viewPlan"
                    >
                      <span>
                        <svg
                         className="me-3"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.5028 4.62704L5.5 6.75V17.2542C5.5 19.0491 6.95507 20.5042 8.75 20.5042L10.7344 20.5043C11.0222 21.0554 11.3866 21.5601 11.8136 22.0042H8.75C6.12665 22.0042 4 19.8776 4 17.2542V6.75C4 5.76929 4.62745 4.93512 5.5028 4.62704ZM20 4.25V12.0218C19.5368 11.7253 19.0335 11.4858 18.5 11.3135V4.25C18.5 3.83579 18.1642 3.5 17.75 3.5H8.75C8.33579 3.5 8 3.83579 8 4.25V17.25C8 17.6642 8.33579 18 8.75 18H10.0189C10.0585 18.5195 10.159 19.0218 10.3135 19.5H8.75C7.50736 19.5 6.5 18.4926 6.5 17.25V4.25C6.5 3.00736 7.50736 2 8.75 2H17.75C18.9926 2 20 3.00736 20 4.25ZM22 17.5C22 14.4624 19.5376 12 16.5 12C13.4624 12 11 14.4624 11 17.5C11 20.5376 13.4624 23 16.5 23C19.5376 23 22 20.5376 22 17.5ZM17.0006 18L17.0011 20.5035C17.0011 20.7797 16.7773 21.0035 16.5011 21.0035C16.225 21.0035 16.0011 20.7797 16.0011 20.5035L16.0006 18H13.4956C13.2197 18 12.9961 17.7762 12.9961 17.5C12.9961 17.2239 13.2197 17 13.4956 17H16.0005L16 14.4993C16 14.2231 16.2239 13.9993 16.5 13.9993C16.7761 13.9993 17 14.2231 17 14.4993L17.0005 17H19.4966C19.7725 17 19.9961 17.2239 19.9961 17.5C19.9961 17.7762 19.7725 18 19.4966 18H17.0006Z"
                            fill="#212121"
                          />
                        </svg>
                        View Plans
                      </span>
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 18L15 12L9 6"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className=" profile-data d-flex justify-content-between"
                      eventKey="helpAndSupport"
                    >
                      <span>
                        <svg
                          className="me-3"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.08984 8.99996C9.32495 8.33163 9.789 7.76807 10.3998 7.40909C11.0106 7.05012 11.7287 6.9189 12.427 7.03867C13.1253 7.15844 13.7587 7.52148 14.2149 8.06349C14.6712 8.60549 14.9209 9.29148 14.9198 9.99996C14.9198 12 11.9198 13 11.9198 13"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 17H12.01"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Help & Support
                      </span>
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 18L15 12L9 6"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className=" profile-data d-flex justify-content-between"
                      eventKey="profileInformation"
                    >
                      <span>
                        <svg
                          className="me-3"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Personal Information
                      </span>
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 18L15 12L9 6"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className=" profile-data text-center"
                      onClick={removeDataFromLocalStorage}
                    >
                      <span className="me-3">
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.25 19.25H4.58333C4.0971 19.25 3.63079 19.0568 3.28697 18.713C2.94315 18.3692 2.75 17.9029 2.75 17.4167V4.58333C2.75 4.0971 2.94315 3.63079 3.28697 3.28697C3.63079 2.94315 4.0971 2.75 4.58333 2.75H8.25"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.667 15.5833L19.2503 11L14.667 6.41663"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M19.25 11H8.25"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      Logout
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={8} className="profile-data user_data">
                <Tab.Content>
                  <Tab.Pane eventKey="booking" className="user-content">
                    <UserBooking />
                  </Tab.Pane>
                  <Tab.Pane eventKey="carPost" className="user-content">
                    <UserPostCar />
                  </Tab.Pane>
                  <Tab.Pane eventKey="myPlan" className="user-content">
                    {/* <h1>Coming Soon</h1> */}
                    <PurchasePlan />
                  </Tab.Pane>
                  <Tab.Pane eventKey="viewPlan" className="user-content">
                    <ViewPlans />
                  </Tab.Pane>
                  <Tab.Pane eventKey="helpAndSupport" className="user-content">
                    <HelpandSupport />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="profileInformation"
                    className="user-content"
                  >
                    <PersonalInformation />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Container>
          </Tab.Container>
        </Col>
      </Container>
    </>
  );
};
export default UserProfilePage;