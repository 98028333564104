import React, { useState, useRef, useEffect } from "react";
import "./filter.css";

import { Button, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ApiPostMethod } from "../js/helper";
import constant from "../js/constant";
import MultiRangeSlider from "../multiRangeSlider/MultiRangeSlider";
import { FaCheck } from 'react-icons/fa';
import { colorOptions } from "../js/constant";
import './BrandList.css';
import { useLocation } from 'react-router-dom';

const LuxuryCarFilter = ({onApiResponse}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const brandKeyFromURL = searchParams.get('brand');
  const [fromYear, setFromYear] = useState("");
  const [toYear, setToYear] = useState("");
  const [checkFrom, setCheckFrom] = useState(false);
  const [checkTo, setCheckTo] = useState(false);
  const [apiAppend, setApiAppend] = useState({
    costMin: '',
    costMax: '',
    brandId: [],
    model: [],
    kmsMin: '',
    kmsMax: '',
    yearMin: '',
    yearMax: '',
    owner: '',
    fuelType: '',
    transmission: '',
    color: [],
    bodyType: '',
  });

  const [costmin, setCostMin] = useState(1000)
  const [costmax, setCostMax] = useState(5000000)
  const [resetSlider, setResetSlider] = useState(false);

  const handlePriceChange = ({ min, max }) => {
    setApiAppend((prevState) => ({
      ...prevState,
      costMin: min,
      costMax: max,
    }));
  };

  const handleFromYearChange = (e) => {
    const inputYear = e.target.value;
    if (inputYear.length <= 4) {
      const currentYear = new Date().getFullYear();
      const minYear = 2000;
      if (inputYear <= currentYear) {
        if (inputYear < minYear) {
          setCheckFrom(true);
        } else {
          setCheckFrom(false);
        }
        setFromYear(inputYear);
      }
    }
  };

  const handleToYearChange = (e) => {
    if (fromYear == "") {
      alert("please enter from year");
    } else {
      const inputYear = e.target.value;
      if (inputYear.length <= 4) {
        const currentYear = new Date().getFullYear();
        const minYear = 2000;
        if (inputYear <= currentYear) {
          if (inputYear <= fromYear) {
            setCheckTo(true);
          } else {
            setCheckTo(false);
          }
          setToYear(inputYear);
        }
      }
    }
  };

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [selectedValue1, setSelectedValue1] = useState("");
  const [selectedValue2, setSelectedValue2] = useState("");
  const [selectedValue3, setSelectedValue3] = useState("");
  const [selectedValue4, setSelectedValue4] = useState("");
  const [selectedValue5, setSelectedValue5] = useState("");
  const [selectedValue6, setSelectedValue6] = useState("");

  
  const options1 = [
    { id: "10k km to 20k km ", label: "10k km to 20k km" },
    { id: "20k km to 30k km ", label: "20k km to 30k km " },
    { id: "30k km to 40k km", label: "30k km to 40k km" },
  ];
  
  const options2 = [
    { id: "Petrol", label: "Petrol" },
    { id: "Diesel ", label: "Diesel " },
    { id: "CNG", label: "CNG" },
    { id: "LPG", label: "LPG" },
    { id: "Electric ", label: "Electric " },
    { id: "Hybrid", label: "Hybrid" },
  ];
  
  const options3 = [
    { id: "Automatic", label: "Automatic" },
    { id: "Manual ", label: "Manual " },
  ];
  
  const options4 = [
    { id: "1 st owner", label: "1 st owner" },
    { id: "2 nd owner", label: "2 nd owner" },
    { id: "3 rd owner", label: "3 rd owner" },
  ];
  
  const options5 = [
    { id: "Hatchback", label: "Hatchback" },
    { id: "Sedan ", label: "Sedan " },
    { id: "SUV", label: "SUV" },
    { id: "MUV", label: "MUV" },
  ];
  
  const options6 = [
    { id: "option16", label: "Option 16" },
    { id: "option17", label: "Option 17" },
    { id: "option18", label: "Option 18" },
  ];
  
  
  const handleRadioChange1 = (event) => {
    const value = event.target.value;
    const regexResult = value.match(/(\d+)k km to (\d+)k km/);

    if (regexResult) {
      setApiAppend((prevState) => ({
        ...prevState,
        kmsMin : parseInt(regexResult[1]) * 1000,
        kmsMax : parseInt(regexResult[2]) * 1000,
      }));
    }
    setSelectedValue1(value);
  };
  
  const handleRadioChange2 = (event) => {
    setSelectedValue2(event.target.value);
    setApiAppend((prevState) => ({
      ...prevState,
      fuelType: event.target.value,
    }));
  };
  
  const handleRadioChange3 = (event) => {
    setSelectedValue3(event.target.value);
    setApiAppend((prevState) => ({
      ...prevState,
      transmission: event.target.value,
    }));
  };
  
  const handleRadioChange4 = (event) => {
    setSelectedValue4(event.target.value);
    const ownerNumber = event.target.value.match(/\d+/)[0];
    setApiAppend((prevState) => ({
      ...prevState,
      owner: ownerNumber,
    }));
  };
  
  const handleRadioChange5 = (event) => {
    setSelectedValue5(event.target.value);
    setApiAppend((prevState) => ({
      ...prevState,
      bodyType: event.target.value.toLowerCase()
    }));
  };
  
  const handleRadioChange6 = (event) => {
    setSelectedValue6(event.target.value);
  };

  function ColorCheckbox({ colorName, colorValue, checked, onChange }) {
    return (
      <label>
        <input 
          type="checkbox" 
          value={colorName} 
          checked={checked} 
          onChange={onChange} 
          style={{ display: 'none' }} 
        />
        <span style={{ backgroundColor: colorValue, width: '40px', height: '40px', display: 'inline-block', margin: '0 5px', position: 'relative', borderRadius: '3px' }}>
          {checked && <FaCheck style={{ position: 'absolute', top: '20%', right: '-10%', transform: 'translate(-50%, -50%)', color:(colorName == 'white') ? 'Black' : 'white', fontSize:'12px' }} />}
        </span>
        {/* {colorName} */}
      </label>
    );
  }

  const [checkedColors, setCheckedColors] = useState([]);

  const handleColorChange = (event) => {
    const selectedColor = event.target.value;
    if (event.target.checked) {
      setCheckedColors([...checkedColors, selectedColor]);
      setApiAppend((prevState) => ({
        ...prevState,
        color: [...apiAppend.color, selectedColor],
      }));
    } else {
      setCheckedColors(checkedColors.filter(color => color !== selectedColor));
      setApiAppend((prevState) => ({
        ...prevState,
        color: apiAppend.color.filter(color => color !== selectedColor),
      }));
    }
  };

  useEffect(() =>{
  },[handleColorChange])

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [carBrands, setCarBrands] = useState([]);
  const [brandExpansions, setBrandExpansions] = useState({});
  const formData = new FormData();

  useEffect(() => {
    let data = formData
    formData.append("brandType", "Luxury");
    let url = `${constant.baseurl}brandType`;
    ApiPostMethod(url, data)
      .then(response => {
        if (response.error) {
          return;
        }
        const carbrands = response.brand;
        setCarBrands(carbrands);
        const selectedBrand = carbrands.find(brand => brand.brand.brandName === brandKeyFromURL);
      
      if (selectedBrand) {
        setSelectedBrands([selectedBrand.brand._id]);
        setApiAppend((prevState) => ({
          ...prevState,
          brandId: [selectedBrand.brand._id],
        }));
      }
        for (let i = 0; i < carbrands.length; i++) {
          const brand = carbrands[i];
          const brandName = brand.brand.brandName;
          const models = brand.model;
        }
      })
    }, []);

    const brands = carBrands;
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleBrandSelection = (_id) => {
    if (selectedBrands.includes(_id)) {
      const brandModels = brands.find((brand) => brand.brand._id === _id)?.model || [];
      setSelectedBrands(selectedBrands.filter((id) => id !== _id));
      setSelectedModels((prevState) => {
        const updatedState = { ...prevState };
        delete updatedState[_id];
        return updatedState;
      });
      setApiAppend((prevState) => ({
        ...prevState,
        brandId: apiAppend.brandId.filter((id) => id !== _id)
      }))
      setApiAppend((prevState) => ({
        ...prevState,
        model: apiAppend.model.filter((model) => !brandModels.includes(model))
      }));
    } else {
      setSelectedBrands([...selectedBrands, _id]);
      const brandModels =
        brands.find((brand) => brand.brand._id === _id)?.model || [];
      setSelectedModels((prevState) => ({
        ...prevState,
        [_id]: brandModels,
      }));
      setApiAppend((prevState) => ({
        ...prevState,
        brandId: [...apiAppend.brandId, _id]
      }))
      setApiAppend((prevState) => ({
        ...prevState,
        model: [...apiAppend.model, ...brandModels]
      }))
    }
  };

  const [selectedModels, setSelectedModels] = useState({});

  const handleModelSelection = (_id, modelName) => {
    setSelectedModels((prevState) => {
      const brandModels = prevState[_id] || [];
      const updatedBrandModels = brandModels.includes(modelName)
        ? brandModels.filter((model) => model !== modelName)
        : [...brandModels, modelName];
  
      setSelectedBrands((prevSelectedBrands) => {
        const allModelsSelected = updatedBrandModels.length === brandModels.length;
  
        if (allModelsSelected) {
          return prevSelectedBrands.includes(_id)
            ? prevSelectedBrands
            : [...prevSelectedBrands, _id];
        } else {
          return prevSelectedBrands.filter((id) => id !== _id);
        }
      });

      
      return {
        ...prevState,
        [_id]: updatedBrandModels,
      };
    });

    const isModelSelected = apiAppend.model.includes(modelName);

  setApiAppend((prevState) => ({
    ...prevState,
    model: isModelSelected
      ? prevState.model.filter((model) => model !== modelName)
      : [...prevState.model, modelName],
    brandId: prevState.model.length > 0
      ? prevState.brandId.includes(_id)
        ? prevState.brandId
        : [...prevState.brandId, _id]
      : prevState.brandId.filter((id) => id !== _id),
  }));
  };

  const handleBrandExpansion = (brandName) => {
    setBrandExpansions((prevExpansions) => ({
      ...prevExpansions,
      [brandName]: !prevExpansions[brandName]
    }));
  };
  
  
  const luxuryBrands = brands.filter(brand => brand.brand.brandType === 'Luxury');
  
  const filteredLuxuryBrands = luxuryBrands.filter(brand =>
    brand.brand.brandName.toLowerCase().includes(searchQuery.toLowerCase())
    || (Array.isArray(brand.model) && brand.model.some(model => model.toLowerCase().includes(searchQuery.toLowerCase())))
  );


  useEffect(() => {
    let data = formData;
    formData.append("costMin", apiAppend.costMin)
    formData.append("costMax", apiAppend.costMax)
    if (Array.isArray(apiAppend.brandId)) {
      for (let brand of apiAppend.brandId) {
        formData.append("brandId", brand);
      }
    } else {
      formData.append("brandId", apiAppend.brandId);
    }
    if (Array.isArray(apiAppend.model)) {
      for (let model of apiAppend.model) {
        formData.append("model", model);
      }
    } else {
      formData.append("model", apiAppend.model);
    }
    formData.append("yearMin", apiAppend.yearMin)
    formData.append("yearMax", apiAppend.yearMax)
    formData.append("kmsMin", apiAppend.kmsMin)
    formData.append("kmsMax", apiAppend.kmsMax)
    formData.append("fuelType", apiAppend.fuelType)
    formData.append("transmission", apiAppend.transmission)
    formData.append("owner", apiAppend.owner)
    formData.append("bodyType", apiAppend.bodyType)
    if (Array.isArray(apiAppend.color)) {
      for (let color of apiAppend.color) {
        formData.append("color", color);
      }
    } else {
      formData.append("color", apiAppend.color);
    }

    let url = `${constant.baseurl}filterSearch`;
    ApiPostMethod(url, data)
    .then((response) => {
      if (response.error === false) {
        onApiResponse(response.carPosts)
      }else{
        return;
      }
    })
  }, [apiAppend])

  const handleReset = () => {
    setApiAppend({
      costMin: '',
      costMax: '',
      brandId: [],
      model: [],
      kmsMin: '',
      kmsMax: '',
      yearMin: '',
      yearMax: '',
      owner: '',
      fuelType: '',
      transmission: '',
      color: [],
      bodyType: '',
    });
    setSelectedBrands([]);
    setSelectedModels({});
    setCheckedColors([]);
    setFromYear('')
    setToYear('')
    setSelectedValue1("")
    setSelectedValue2("")
    setSelectedValue3("")
    setSelectedValue4("")
    setSelectedValue5("")
    setResetSlider((prevState) => !prevState);
  }

  return (
    <>
      <Col lg="12" className="price-range filter-item filter-item-top ">
        <h6 className="font-weight-bold price_range">Price Range</h6>
        <MultiRangeSlider
         minValue={costmin}
         maxValue={costmax}
         onChange={handlePriceChange}
         resetSlider={resetSlider}
         key={resetSlider}
        />
      </Col>
      <Col lg="12" className="filter-item">
        <h6 className="font-weight-bold pb-2">Brand + Model</h6>
        <div className="filterBrand">
          <div>
            <div className="search-field mb-2">
              <input type="text" placeholder="Search" value={searchQuery} onChange={handleSearch} className="form-control brand-search"/>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 19.1354C15.4183 19.1354 19 15.5537 19 11.1354C19 6.7171 15.4183 3.13538 11 3.13538C6.58172 3.13538 3 6.7171 3 11.1354C3 15.5537 6.58172 19.1354 11 19.1354Z" stroke="#212529" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21 21.1354L16.65 16.7854" stroke="#212529" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div className="brand-section">
              <h6>Luxury Brands</h6>
              <ul className="brand-list">
              {filteredLuxuryBrands.length > 0
                ? filteredLuxuryBrands.map((brand, index) => (
                    <li key={index}>
                      <div className="d-flex align-items-center">
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedBrands.includes(brand.brand._id)}
                            onChange={() =>
                              handleBrandSelection(brand.brand._id)
                            }
                          />
                        </label>
                        <div className="d-flex justify-content-between w-100">
                          <span className="m-0"onClick={()=> handleBrandExpansion(brand.brand.brandName)}  style={{cursor:"pointer"}}>{brand.brand.brandName} <span className="model-count">({brand.noOfCars})</span></span>
                          <span
                           style={{cursor:"pointer"}}
                           onClick={()=> handleBrandExpansion(brand.brand.brandName)}
                            className={
                              brandExpansions[brand.brand.brandName]
                                ? "baseposition rotate"
                                : "baseposition"
                            }
                          >
                            <svg
                              width="20"
                              height=""
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.0459 9.03061L12.0767 15.0613L6.04603 9.09212"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <ul
                        className={
                          brandExpansions[brand.brand.brandName]
                            ? "models-list expanded"
                            : "models-list"
                        }
                      >
                        {typeof brand.model == "object"
                          ? brand.model.map((model, index) => (
                              <li key={index}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedModels[brand.brand._id]?.includes(model)}
                                    onChange={() => handleModelSelection(brand.brand._id,model)}
                                  />
                                  {model}
                                </label>
                              </li>
                            ))
                          : brand.model}
                      </ul>
                    </li>
                  ))
                : "Brands Not Found"}
            </ul>
            </div>
          </div>
        </div>
      </Col>
      <Col lg="12" className="filter-item">
        <h6 className="font-weight-bold">Year</h6>
        <Col lg="12" className="d-flex">
          <Col lg="6" className="p-1">
            <label htmlFor="from-year-input">From:</label>
            <input
              type="text"
              id="from-year-input"
              name="from-year"
              value={fromYear}
              onChange={handleFromYearChange}
              className={
                checkFrom ? "error-border form-control" : "form-control"
              }
            />
          </Col>
          <Col lg="6" className="p-1">
            <label htmlFor="to-year-input">To:</label>
            <input
              type="text"
              id="to-year-input"
              name="to-year"
              value={toYear}
              onChange={handleToYearChange}
              className={checkTo ? "error-border form-control" : "form-control"}
            />
          </Col>
        </Col>
      </Col>
      <Col lg="12">
      <div className="filter-item">
            <button className="filter-btn" onClick={() => setIsOpen1(!isOpen1)}>
                KM Driven
                <span className={isOpen1 ? "rotate" : "normal"}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0459 9.03061L12.0767 15.0613L6.04603 9.09212" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
            </button>
                <ul className={isOpen1 ? "filter-list expand-item" : "filter-list"}>
                {options1.map((option) => (
                    <li key={option.id}>
                    <label>
                        <input
                        type="radio"
                        name="option1"
                        value={option.id}
                        checked={selectedValue1 === option.id}
                        onChange={handleRadioChange1}
                        />
                        {option.label}
                    </label>
                    </li>
                ))}
                </ul>
        </div>
        <div className="filter-item">
            <button className="filter-btn" onClick={() => setIsOpen2(!isOpen2)}>
                Fuel Type
                <span className={isOpen2 ? "rotate" : "normal"}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0459 9.03061L12.0767 15.0613L6.04603 9.09212" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
            </button>
                <ul className={isOpen2 ? "filter-list expand-item" : "filter-list"}>
                {options2.map((option) => (
                    <li key={option.id}>
                    <label>
                        <input
                        type="radio"
                        name="option2"
                        value={option.id}
                        checked={selectedValue2 === option.id}
                        onChange={handleRadioChange2}
                        />
                        {option.label}
                    </label>
                    </li>
                ))}
                </ul>
        </div>

        <div className="filter-item">
            <button className="filter-btn" onClick={() => setIsOpen3(!isOpen3)}>
                Transmission
                <span className={isOpen3 ? "rotate" : "normal"}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0459 9.03061L12.0767 15.0613L6.04603 9.09212" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
            </button>
                <ul className={isOpen3 ? "filter-list expand-item" : "filter-list"}>
                {options3.map((option) => (
                    <li key={option.id}>
                    <label>
                        <input
                        type="radio"
                        name="option3"
                        value={option.id}
                        checked={selectedValue3 === option.id}
                        onChange={handleRadioChange3}
                        />
                        {option.label}
                    </label>
                    </li>
                ))}
                </ul>
        </div>

        <div className="filter-item">
            <button className="filter-btn" onClick={() => setIsOpen4(!isOpen4)}>
                Owner
                <span className={isOpen4 ? "rotate" : "normal"}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0459 9.03061L12.0767 15.0613L6.04603 9.09212" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
            </button>
                <ul className={isOpen4 ? "filter-list expand-item" : "filter-list"}>
                {options4.map((option) => (
                    <li key={option.id}>
                    <label>
                        <input
                        type="radio"
                        name="option4"
                        value={option.id}
                        checked={selectedValue4 === option.id}
                        onChange={handleRadioChange4}
                        />
                        {option.label}
                    </label>
                    </li>
                ))}
                </ul>
        </div>

        <div className="filter-item">
            <button className="filter-btn" onClick={() => setIsOpen5(!isOpen5)}>
                Body Type
                <span className={isOpen5 ? "rotate" : "normal"}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0459 9.03061L12.0767 15.0613L6.04603 9.09212" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
            </button>
                <ul className={isOpen5 ? "filter-list expand-item" : "filter-list"}>
                {options5.map((option) => (
                    <li key={option.id}>
                    <label>
                        <input
                        type="radio"
                        name="option5"
                        value={option.id}
                        checked={selectedValue5 === option.id}
                        onChange={handleRadioChange5}
                        />
                        {option.label}
                    </label>
                    </li>
                ))}
                </ul>
        </div>

        <div className="filter-item">
            <button className="filter-btn" onClick={() => setIsOpen6(!isOpen6)}>
                Color
                <span className={isOpen6 ? "rotate" : "normal"}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0459 9.03061L12.0767 15.0613L6.04603 9.09212" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
            </button>
                <ul className={isOpen6 ? "filter-list expand-item" : "filter-list"}>
                    {colorOptions.map((option) => (
                        <ColorCheckbox
                        key={option.name}
                        colorName={option.name}
                        colorValue={option.value}
                        checked={checkedColors.includes(option.name)}
                        onChange={handleColorChange}
                        />
                    ))}
                </ul>
        </div>
      </Col>
      <Col lg="12">
        <Button variant="danger" className="btn-cars2cars" onClick={handleReset} style={{borderRadius:"3px"}}>Reset</Button>
      </Col>
    </>
  );
};
export default LuxuryCarFilter;