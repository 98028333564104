import React, { useState, useEffect } from "react";
import { FaSistrix } from "react-icons/fa";
import constant from "./js/constant";
import { ApiGetMethod, ApiPostMethod } from "./js/helper";
import { useNavigate, useLocation } from "react-router-dom";

const SearchBar = ({ keyword, setKeyword }) => {
  const BarStyling = {
 
    border: "none",
    padding: "0.5rem",
    outline: "none",
    background: "transparent",
  };

  const [carData, setCarData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let data = new FormData();
    let url = `${constant.baseurl}carPosted`;

    ApiGetMethod(url, data)
      .then((response) => {
        if (response.error) {
          return;
        }
        const cardata = response.data;
        setCarData(cardata);
      })
  }, []);

  useEffect(() => {
    // Hide suggestions when navigating to a different page
    setShowSuggestions(false);
  }, [location]);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    setShowSuggestions(value.trim().length > 0);
  };

  const handleSuggestionClick = (suggestion) => {
    const { brandName, model } = splitSuggestion(suggestion);
    let encodedQuery = "";

    if (brandName) {
      encodedQuery += `brand=${encodeURIComponent(brandName)}`;
    }
    if (model) {
      if (encodedQuery) {
        encodedQuery += "&";
      }
      encodedQuery += `model=${encodeURIComponent(model)}`;
    }

    // Construct the API URL for updating brand and model counts
    const updateCountUrl = `${constant.baseurl}carSearch`;

    // Create a single FormData object to send both brand and model data in one API call
    const data = new FormData();

    // Update brand count if brandName is present
    if (brandName) {
      data.append("brandName", brandName);
    }

    // Update model count if model is present
    if (model) {
      data.append("model", model);
    }

    // Check if either brand or model is present before making the API call
    if (brandName || model) {
      // Update both brand and model counts
      ApiPostMethod(updateCountUrl, data)
        .then((response) => {
        })
    }

    navigate(`/carSearch?${encodedQuery}`);
  };

  // Helper function to split suggestion into brandName and model
  const splitSuggestion = (suggestion) => {
    const [brandName, model] = suggestion.split(" - ");
    return { brandName, model: model ? model.trim() : null };
  };

  const navigate = useNavigate();

  // Use Sets to store unique brand names and models
  const uniqueBrands = new Set(
    carData
      .filter(
        (car) =>
          car.brandName &&
          car.brandName.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .map((car) => car.brandName)
  );

  // Filter carData to get brand-model combinations for the entered model name
  const brandModelsForModel = carData.filter(
    (car) =>
      car.model &&
      car.model.toLowerCase().includes(searchQuery.toLowerCase()) &&
      car.brandName
  );

  // Create a Set to store unique suggestions
  const uniqueSuggestions = new Set();

  // If the user input matches any existing brand names, show brand names and brand-model combinations as suggestions
  if (uniqueBrands.size > 0) {
    uniqueBrands.forEach((brand) => {
      // Add brand name as suggestion
      uniqueSuggestions.add(brand);

      // Add brand-model combinations as suggestions for the matching brand name
      const brandModels = carData.filter(
        (car) => car.brandName === brand && car.model
      );
      brandModels.forEach((car) =>
        uniqueSuggestions.add(`${brand} - ${car.model}`)
      );
    });
  } else if (brandModelsForModel.length > 0) {
    // If the user entered a model name, show brand-model combinations for the matching model
    brandModelsForModel.forEach((car) =>
      uniqueSuggestions.add(`${car.brandName} - ${car.model}`)
    );
  } else {
    // If the user input does not match any existing brand names or model names, show all brand-model combinations as suggestions
    carData.forEach((car) => {
      if (car.brandName && car.model) {
        uniqueSuggestions.add(`${car.brandName} - ${car.model}`);
      }
    });
  }

  // Convert Set back to an array
  const suggestionsArray = Array.from(uniqueSuggestions);

  return (
    <>
      <div className="col-12 d-flex justify-content-between align-items-center">
        <div >
        <input
          style={BarStyling}
          key="random1"
          value={keyword}
          placeholder={"Search here"}
          onChange={handleSearch}
        />
        </div>
        <div>
        <FaSistrix
        />
          </div>
        {showSuggestions && (
          <ul
            style={{
              position: "absolute",
              top: "100%",
              backgroundColor: "#ffffff",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "10px",
              zIndex: 1,
              display: "block",
            }}
          >
            {/* Display unique suggestions */}
            {suggestionsArray.map((suggestion, i) => (
              <li
                key={i}
                style={{ marginBottom: "5px", cursor: "pointer" }}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default SearchBar;