import { useEffect, useState } from "react";
import { Button, CloseButton, Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import constant from "../js/constant";
import { ApiPostMethod } from "../js/helper";
import "./userProfile.css";

import notfoundImage from "../../image/not-foundimg.png";
import { NavLink } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

const UserPostCar = () => {
  const userIdFCPost = localStorage.hasOwnProperty("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "";
  const [errorMessage, setErrorMessage] = useState(false);
  const [userPostedCar, setUserPostedCar] = useState([]);

  const [userPostedDate, setUserPostedDate] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const formData = new FormData();
  useEffect(() => {
    setIsLoading(true);
    let data = formData;
    let url = `${constant.baseurl}viewAllCarPost/` + userIdFCPost._id;
    ApiPostMethod(url, data).then((response) => {
      if (response.error == false) {
        setErrorMessage(false);
        setUserPostedCar(response.carPostAppointment);
        setIsLoading(false);
      } else {
        setErrorMessage(true);
        setIsLoading(false);
      }
    });
  }, []);

  const postsByDate = {};
  userPostedCar.forEach((post) => {
    const options = {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    const date = new Date(post.carPost.updatedAt).toLocaleDateString(
      "en-US",
      options
    );
    if (!postsByDate[date]) {
      postsByDate[date] = [];
    }
    postsByDate[date].push(post);
  });

  const sortedDates = Object.keys(postsByDate).sort((a, b) => {
    return new Date(b) - new Date(a);
  });

  const handledeprication = (id) => { 
    const matchingCarPost = userPostedCar.find(carPost => carPost.carPost._id === id);
    const isImagesEmpty = matchingCarPost.carPost.carImages.length === 0;
    const isColorEmpty =
      typeof matchingCarPost.carPost.color !== "string" || matchingCarPost.carPost.color.trim() === "";

    if (isImagesEmpty || isColorEmpty) {
      alert("Please select at least one image and fill in the color field");
      return;
    }

    const userid = localStorage.hasOwnProperty("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    const userId = localStorage.hasOwnProperty("user") ? userid._id : "";
    for (let i = 0; i <= matchingCarPost.carPost.carImages.length; i++) {
      window.carImage = matchingCarPost.carPost.carImages[i];
    }
    const carPostFormData = {
      brandId: matchingCarPost.carPost.brandid,
      model: matchingCarPost.carPost.model,
      varient: matchingCarPost.carPost.varient,
      year: matchingCarPost.carPost.year,
      city: matchingCarPost.carPost.city,
      owner: matchingCarPost.carPost.owner,
      kms: matchingCarPost.carPost.kms,
      whenToSell: matchingCarPost.carPost.whento,
      insurence: matchingCarPost.carPost.insurance,
      rc: matchingCarPost.carPost.rc,
      transmission: matchingCarPost.carPost.transmission,
      color: matchingCarPost.carPost.color,
      carno: matchingCarPost.carPost.carNumber,
    };
    const formData = new FormData();
    for (let i = 0; i <= matchingCarPost.carPost.carImages.length; i++) {
      formData.append(`image`, matchingCarPost.carPost.carImages[i]);
    }
    formData.append("userId", userId);
    formData.append("brandId", matchingCarPost.carPost.brandId);
    formData.append("brandName", matchingCarPost.carPost.brandName);
    formData.append("model", matchingCarPost.carPost.model);
    formData.append("varient", matchingCarPost.carPost.varient);
    formData.append("year", matchingCarPost.carPost.year);
    formData.append("city", matchingCarPost.carPost.city.toLowerCase());
    formData.append("owner", matchingCarPost.carPost.owner);
    formData.append("kms", matchingCarPost.carPost.kms);
    formData.append("whenToSell", matchingCarPost.carPost.whenToSell);
    formData.append("insurence", matchingCarPost.carPost.insurance);
    formData.append("rc", matchingCarPost.carPost.rc);
    formData.append("transmission", matchingCarPost.carPost.transmission);
    formData.append("color", matchingCarPost.carPost.color);
    formData.append("carNumber", matchingCarPost.carPost.carNumber);
    let data = formData;
    let url = `${constant.baseurl}carData`;
    ApiPostMethod(url, data).then((response) => {
      if (response.error == false) {
        localStorage.setItem("deprication", JSON.stringify(response));
        navigate("/Appoinment?SelectedCity=" + matchingCarPost.carPost.city + "&carPostId=" + matchingCarPost.carPost._id);
      } else {
        localStorage.setItem("formData", JSON.stringify(carPostFormData));
        localStorage.setItem("sellCar", true);
        Swal.fire({
          title: "Error!",
          text: response.message,
          icon: "info",
          confirmButtonText: "OK",
        }).then((result) => {
          if (response.navigate) {
            navigate("/Login");
          } else {
            navigate("/UserProfilePage?activeTabKey=carPost");
          }
        });
      }
    });
  }

  return (
    <>
      <Breadcrumb className="bread_crumb">
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>My Post</Breadcrumb.Item>
      </Breadcrumb>
      {
        isLoading?(
          <div className="text-center">
          <Spinner animation="border" variant="danger" />
        </div>
        ):(  <Container fluid>
          {errorMessage ? (
            <Col
              lg="12"
              className="not-found d-flex justify-content-center align-items-center"
            >
              <Col lg="8" className="text-center">
                <div className="notfound-img d-flex justify-content-center">
                  <img src={notfoundImage} alt="car Not Found" />
                </div>
                <h5 className="text-color font-weight-bold">No Car Posted !!!</h5>
                <h6 className="text-grey">Please post your Car.</h6>
                <NavLink to="/carPost" className="btn-cars_cars">
                  Sell Car
                </NavLink>
              </Col>
            </Col>
          ) : (
            <div className="CarpostListGroup">
              {sortedDates.map((date) => (
                <div key={date} className="post-group">
                  <h5 className="post-date">{date}</h5>
                  <ul className="CarpostList p-0">
                    {postsByDate[date].map((post, index) => (
                      <li key={index} className="post-list-item">
                        <Col
                          lg="12"
                          className="userPost d-flex justify-content-between"
                        >
                          <Col
                            lg="3"
                            className="postedcarImage"
                            onClick={() =>
                              post.carPost.status === "posted"
                                ? navigate(
                                  "/SingleCarDetail?carPostId=" +
                                    post.carPost._id
                                )
                                : post.carPost.status === "sold" ?
                                  navigate(
                                    "/SingleCarDetail?carPostId=" +
                                      post.carPost._id
                                  )
                                : toast.info(`Car status is ${post.carPost.status}!...`, {
                                  position: "top-right",
                                  theme: "light",
                                })
                            }
                          >
                            <img
                              src={post.carPost.carImages[0]}
                              alt=""
                              className="w-100"
                            />
                          </Col>
                          <Col
                            lg="5"
                            className="postedCarDetails"
                            onClick={() =>
                              post.carPost.status === "posted"
                                ? navigate(
                                  "/SingleCarDetail?carPostId=" +
                                    post.carPost._id
                                )
                                : post.carPost.status === "sold" ?
                                  navigate(
                                    "/SingleCarDetail?carPostId=" +
                                      post.carPost._id
                                  )
                                : toast.info(`Car status is ${post.carPost.status}!...`, {
                                  position: "top-right",
                                  theme: "light",
                                })
                            }
                          >
                            <h5>
                              <span>{post.brand.brandName} </span>
                              <span> {post.carPost.model}</span>{" "}
                              <span>{post.carPost.year}</span>
                              <span className="text-uppercase"> - {post.carPost.carNumber
                                ? post.carPost.carNumber
                                    .replace(/\s/g, "")
                                    .substring(0, 4) + "-XX-XXXX"
                                : ""}
                              </span>
                            </h5>
                            <h6>
                              <span>
                                {(post.carPost.kms / 1000).toLocaleString(
                                  "en-US",
                                  { maximumFractionDigits: 1 }
                                ) + "k"}{" "}
                                km{" "}
                              </span>
                              <span> {post.carPost.varient} </span>{" "}
                              <span> {post.carPost.transmission} </span>
                            </h6>
                            <h6>
                              <span>
                                <svg
                                  width="19"
                                  height="20"
                                  viewBox="0 0 19 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_1204_2121)">
                                    <path
                                      d="M16.625 8.50793C16.625 14.0496 9.5 18.7996 9.5 18.7996C9.5 18.7996 2.375 14.0496 2.375 8.50793C2.375 6.61827 3.12567 4.806 4.46186 3.4698C5.79806 2.1336 7.61033 1.38293 9.5 1.38293C11.3897 1.38293 13.2019 2.1336 14.5381 3.4698C15.8743 4.806 16.625 6.61827 16.625 8.50793Z"
                                      stroke="#333333"
                                      stroke-width="1.61006"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9.5 10.8829C10.8117 10.8829 11.875 9.81961 11.875 8.50793C11.875 7.19626 10.8117 6.13293 9.5 6.13293C8.18832 6.13293 7.125 7.19626 7.125 8.50793C7.125 9.81961 8.18832 10.8829 9.5 10.8829Z"
                                      stroke="#333333"
                                      stroke-width="1.61006"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1204_2121">
                                      <rect
                                        width="19"
                                        height="19"
                                        fill="white"
                                        transform="translate(0 0.591309)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              Cars 2 Cars Hub, {post.carPost.city}
                            </h6>
                          </Col>
                          <Col
                            lg="3"
                            className="carpostbtn d-flex "
                          >
                            <Col lg="5" className="Profile_edit">
                              <Button className="btn-cars_cars ">Edit</Button>
                            </Col>
                            <Col lg="6" className="Profile_view">
                              {/* <CloseButton
                                onClick={() =>
                                  post.carPost.status === "pending"
                                    ? toast.info("Car status is pending!...", {
                                        position: "top-right",
                                        theme: "light",
                                      })
                                    : navigate(
                                        "/SingleCarDetail?carPostId=" +
                                          post.carPost._id
                                      )
                                }
                                // className={
                                //   post.errorAppointment == true
                                //     ? "btn-cars_cars d-none"
                                //     : "btn-cars_cars"
                                // }
                                className={post.carPost.city === "pune" ? post.errorAppointment == true ?  "btn-cars_cars d-none" : "btn-cars_cars" : "btn-cars_cars"}
                              >
                                View
                              </CloseButton>
  
                              <Button
                                onClick={() =>
                                  navigate(
                                    "/Appoinment?carPostId=" + post.carPost._id
                                  )
                                }
                                className={post.carPost.city === "pune" ? post.errorAppointment == true ?  "btn-cars_cars" : "btn-cars_cars d-none" : "btn-cars_cars d-none"}
                              >
                                Book Slot
                              </Button> */}
                              {post.carPost.city === "pune" ? 
                                  post.errorAppointment === true ? 
                                  <Button
                                    onClick={() => handledeprication(post.carPost._id)}
                                    className="btn-cars_cars"
                                  >
                                    Book Slot
                                  </Button>
                                  :
                                  <CloseButton
                                    onClick={() =>
                                      post.carPost.status === "posted"
                                        ? navigate(
                                          "/SingleCarDetail?carPostId=" +
                                            post.carPost._id
                                        )
                                        : post.carPost.status === "sold" ?
                                          navigate(
                                            "/SingleCarDetail?carPostId=" +
                                              post.carPost._id
                                          )
                                        : toast.info(`Car status is ${post.carPost.status}!...`, {
                                          position: "top-right",
                                          theme: "light",
                                        })
                                    }
                                    className="btn-cars_cars1"
                                    
                                  >
                                    View
                                  </CloseButton>
                                  :
                                  <CloseButton
                                    onClick={() =>
                                      post.carPost.status === "posted"
                                        ? navigate(
                                          "/SingleCarDetail?carPostId=" +
                                            post.carPost._id
                                        )
                                        : post.carPost.status === "sold" ?
                                          navigate(
                                            "/SingleCarDetail?carPostId=" +
                                              post.carPost._id
                                          )
                                        : toast.info(`Car status is ${post.carPost.status}!...`, {
                                          position: "top-right",
                                          theme: "light",
                                        })
                                    }
                                    className="btn-cars_cars1"
                                    
                                  >
                                    View
                                  </CloseButton>
                              }
                            </Col>
                          </Col>
                        </Col>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}
        </Container>)
      }
    
      <ToastContainer />
    </>
  );
};
export default UserPostCar;
