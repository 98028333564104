import BottomNavbar from "../navbar/bottomNavBar";
import "./carpost.css";
import MyForm from "./carpostForm/carPostForm";

const carPost = () => {
  return (
    <section className="carpost-section top_class" id="carpost_section">
      <div className="form-section">
        <div className="form-header">
          <h1>Check Your Car’s Price</h1>
        </div>
        <div className="form-content">
          <MyForm />
        </div>
      </div>
    </section>
  );
};
export default carPost;
