export function validate(name, value){
    if(name == 'name'){
        if(value.length > 2){
            return '';
        }else{
            return 'Please enter valid name';
        }
    }
    if(name == 'mobile'){
        if(/^[6-9]{1}[0-9]{9}$/.test(value)){
            return '';
        }else{
            return 'Please enter valid mobile ';
        }
    }
    if(name == 'email'){
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(regEmail.test(value)){
            return '';
        }else{
            return 'Please enter valid email';
        }
    }
    if(name == 'address'){
        if(value.length > 5){
            return '';
        }else{
            return 'Please enter valid address';
        }
    }
    if(name == 'city'){
        if(value.length > 2){
            return '';
        }else{
            return 'Please enter valid city';
        }
    }
    if(name == 'brand'){
        if(value.length > 2){
            return '';
        }else{
            return 'please Enter Brand Name!';
        }
    }
    if(name == 'model'){
        if(value.length > 2){
            return '';
        }else{
            return 'please Enter Model!';
        }
    }
    if(name == 'variant'){
        if(value.length > 2){
            return '';
        }else{
            return 'please Enter Varient!';
        }
    }
    if(name == 'price'){
        if(value.length > 2){
            return '';
        }else{
            return 'please Enter Price!';
        }
    }
    if(name == 'color'){
        if(value.length > 2){
            return '';
        }else{
            return 'please Enter Color!';
        }
    }
    if(name == 'fueltype'){
        if(value.length > 2){
            return '';
        }else{
            return 'please Enter Fuel Type!';
        }
    }
    if(name == 'transmission'){
        if(value.length > 2){
            return '';
        }else{
            return 'please Enter Transmission!';
        }
    }
    // if(name == 'other'){
    //     if(value.length > 2){
    //         return '';
    //     }else{
    //         return 'please Enter!';
    //     }
    // }
    
}