import { useState, useEffect } from "react";
import "./login.css";
import Login_Logo from "./Cars2Cars_Logo.png";
import constant from "../js/constant";
import { ApiPostMethod } from "../js/helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Registermodal from "../register/registerModal";

function LoginModal({ show, handleClose, mobileNo }) {
  const [mobileNumber, setMobileNumber] = useState("");
  useEffect(()=>{
    setMobileNumber(mobileNo);
  },[mobileNo])
  const [registerNO, setregisterNO] = useState("");
  const [showOtp, setShowOtp] = useState();
  const [otp, setOtp] = useState("");
  const [showSendOtp, setShowSendOtp] = useState(true);
  const [showRegister, setShowRegister] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  const [showResendOtpButton, setShowResendOtpButton] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [adminInfo, setAdminInfo] = useState({});
  const [mobileNumberDisabled, setMobileNumberDisabled] = useState(false);
  const [showingToast, setShowingToast] = useState(false);
  const navigate = useNavigate();
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const handlemobno = (event) => {
    setMobileNumber(event.target.value);
  };
  const [resendTimer, setResendTimer] = useState(0);
  const [loginTimer, setloginTimer] = useState(0);
  const [otpTimer, setotpTimer] = useState(0);

  const handleSendOtp = async () => {
    var OtpNumber = document.getElementById("mobile-number");
    const mobileNumberRegex = /^[6-9]\d{9}$/;

    if (mobileNumber.length == "") {
      setErrorMessage("Please Enter Mobile Number.");
      setMobileNumber("");
    } else {
      if (!mobileNumberRegex.test(mobileNumber))  {
        setErrorMessage("Please Enter Valid Mobile Number.");
        setMobileNumber("");
      } else {
        try {
          const formData = new FormData();
          formData.append("mobile", mobileNumber);
          let data = formData;
          let url = `${constant.baseurl}getOtp`;
          
          ApiPostMethod(url, data).then((response) => {
            if (response.error == false) {
              setShowOtp(response.message);
              setErrorMessage("");
              setShowOtpField(true);
              setShowResendOtpButton(true);
              setShowLoginButton(true);
              setShowSendOtp(false);
              setMobileNumberDisabled(true);
              setShowRegister(false);
              setResendTimer(10);
              const countdownInterval = setInterval(() => {
                setResendTimer(prevTimer => prevTimer - 1);
              }, 1000);
              setTimeout(() => {
                clearInterval(countdownInterval);
              }, (10 * 1000));
            } else {
              if (response.time) {
                setShowOtpField(false);
                setShowResendOtpButton(false);
                setShowLoginButton(false);
                setShowOtpField(false);
                setShowResendOtpButton(false);
                setShowLoginButton(false);

                const extractedNumber = response.time.match(/\d+/)[0];
                const initialTimeInSeconds = extractedNumber * 60;
                setloginTimer(initialTimeInSeconds);

                const countdownInterval = setInterval(() => {
                  setloginTimer((prevTimer) => {
                    if (prevTimer === 0) {
                      clearInterval(countdownInterval);
                      setErrorMessage('');
                      return 0;
                    }
                    const minutes = Math.floor(prevTimer / 60);
                    const seconds = prevTimer % 60;
                    setErrorMessage(`Login attempt crossed. Wait for ${minutes}:${seconds < 10 ? "0" : ""}${seconds} min`);
                    return prevTimer - 1;
                  });
                }, 1000);
              } else {
                setShowOtpField(false);
                setShowResendOtpButton(false);
                setShowLoginButton(false);
                setErrorMessage(response.message);
                setAdminInfo(response.Admin ? response.Admin : {});
                setShowRegister(response.Admin ? false : true);
                // setShowSendOtp(response.Admin ? false : true);
                setShowSendOtp(response.registerUser == true ? false : true);
                setregisterNO(mobileNumber)
              }
            }
          });
        } catch (error) {
          setErrorMessage("Error sending OTP. Please try again.");
        }
      }
    }
  };
  const handleResendOtp = async () => {
    try {
      const formData = new FormData();
      formData.append("mobile", mobileNumber);
      let data = formData;
      let url = `${constant.baseurl}getOtp`;
      ApiPostMethod(url, data).then((response) => {
        if (response.error == false) {
          setOtp("");
          setErrorMessage("");
          setResendTimer(10);

          const countdownInterval = setInterval(() => {
            setResendTimer(prevTimer => prevTimer - 1);
          }, 1000);
          setTimeout(() => {
            clearInterval(countdownInterval);
          }, (10 * 1000));
        } else {
          if (response.time) {
            const extractedNumber = response.time.match(/\d+/)[0];
            // console.log("extracted", extractedNumber);
            const initialTimeInSeconds = extractedNumber * 60;
            setotpTimer(initialTimeInSeconds);

            const countdownInterval = setInterval(() => {
              setotpTimer((prevTimer) => {
                if (prevTimer === 0) {
                  clearInterval(countdownInterval);
                  setErrorMessage('');
                  return 0;
                }
                const minutes = Math.floor(prevTimer / 60);
                const seconds = prevTimer % 60;
                setErrorMessage(`OTP attempt crossed. Wait for ${minutes}:${seconds < 10 ? "0" : ""}${seconds} min`);
                return prevTimer - 1;
              });
            }, 1000);
          } else {
            setErrorMessage("Error resending OTP.");
          }
        }
      });
    } catch (error) {
      setErrorMessage("Error resending OTP. Please try again.");
    }
  };
  
  const handleLogin = async () => {
    try {
      const formData = new FormData();
      formData.append("mobile", mobileNumber);
      formData.append("otp", otp);
      let data = formData;
      let url = `${constant.baseurl}login`;
      ApiPostMethod(url, data).then((response) => {
        if (response.error == false) {
          localStorage.setItem("token", response.token);
          localStorage.setItem("user", JSON.stringify(response.user));
          setErrorMessage("");
          setShowingToast(true);
          toast.success("User Logged in Successfully", {
            position: "top-center",
            autoClose: 3000,
            theme: "light",
            onClose: () => {
              handleClose();
              window.location.reload();
            },
          });
          setMobileNumber("");
          setOtp("");
        } else {
          setErrorMessage("Invalid OTP. Please try again.");
        }
      });
    } catch (error) {
      setErrorMessage("Error logging in. Please try again.");
    }
  };
  
  const handleCloseModal = () => {
    setMobileNumber("");
    setShowOtp("");
    setOtp("");
    setShowSendOtp(true);
    setShowRegister(false);
    setShowOtpField(false);
    setShowResendOtpButton(false);
    setShowLoginButton(false);
    setErrorMessage("");
    setAdminInfo({});
    setMobileNumberDisabled(false);
    setShowingToast(false);
    handleClose();
  };
  const handleRegisterClose = () => {
    setShowRegisterModal(false);
  };

  const handleRegisterShow = (mobileNo) => {
    setregisterNO(mobileNo);
    setShowRegisterModal(true);
    setErrorMessage("");
    handleCloseModal();
  };
  
  return (
    <>
      <Modal show={show} onHide={handleCloseModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="login_modal_body">
          <section className="login-modal">
            <div className="container-fluid d-flex h-100">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <div
                  className="col-12 login-form"
                  style={{ boxShadow: "none" }}
                  >
                  <div className="login-logo">
                    <img src={Login_Logo} alt="" />
                  </div>
                  <form>
                    <input
                      type="tel"
                      className="form-control mb-2"
                      id="mobile-number"
                      name="mobile-number"
                      value={mobileNumber}
                      onChange={handlemobno}
                      disabled={mobileNumberDisabled}
                      placeholder="Enter your mobile no."
                    />
                    {showOtpField && (
                      <div className="col-12 d-flex">
                        <div className="col-8">
                          <input
                            className="form-control"
                            type="number"
                            id="otp"
                            name="otp"
                            value={otp}
                            onChange={(event) => {
                              const inputValue = event.target.value.slice(0, 6);
                              setOtp(inputValue);
                            }}
                            placeholder="OTP"
                            
                          />  
                        </div>
                        <div className="col-4">
                          {showResendOtpButton && (
                            <button
                              className="btn btn-theme btn_resend"
                              type="button"
                              onClick={handleResendOtp}
                              disabled={resendTimer > 0 || loginTimer > 0}
                              style={{cursor: resendTimer > 0 || loginTimer > 0 ? "not-allowed" : "pointer"}}
                            >
                              {resendTimer > 0 ? `${resendTimer}sec` : "Resend OTP"}
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                    {errorMessage && (
                      <p>
                        {errorMessage}{" "}
                        {Object.keys(adminInfo).length > 0 && (
                          <NavLink
                            className="text-decoration-none"
                            style={{ color: "black" }}
                            to="/?section=Help"
                            onClick={() => handleClose()}
                          >
                            i
                          </NavLink>
                        )}
                      </p>
                    )}
                    <div className="btn-block">
                      {showSendOtp &&  (
                        <button
                          className="btn btn-theme sendOtp"
                          type="button"
                          onClick={handleSendOtp}
                          disabled={loginTimer > 0}
                          style={{cursor: loginTimer > 0 ? "not-allowed" : "pointer"}}
                        >
                          Send OTP
                        </button>
                      )}
                      {showRegister && (
                        <NavLink>
                          <button
                            className="btn btn-theme"
                            type="button"
                            onClick={() =>handleRegisterShow(mobileNumber)}
                          >
                            Register
                          </button>
                        </NavLink>
                      )}
                      {showLoginButton && (
                        <button
                          className="btn btn-theme"
                          type="button"
                          onClick={handleLogin}
                          disabled={loginTimer > 0}
                          style={{cursor: loginTimer > 0 ? "not-allowed" : "pointer"}}
                        >
                          Login
                        </button>
                      )}
                      {showingToast && <ToastContainer />}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
      <Registermodal
        registerShow={showRegisterModal}
        registerClose={handleRegisterClose}
        mobileNumber={registerNO}
      />
    </>
  );
}

export default LoginModal;