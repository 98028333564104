
        
export function ApiPostMethod(url, data, method = 'POST'){
    return fetch(url, {
      method: method,
      header: {'content-type' : 'multipart/form-data'},
      body: data
    })
    .then((resp) => resp.json())
    .then(function(result) {
        return result;
    });
}

export function ApiGetMethod(url, data, method = "GET"){
  return fetch(url, {
    method:method,
    header: {'content-type' : 'multipart/form-data'}
  })
  .then((resp) => resp.json())
  .then(function(result) {
    return result;
  });
}