import React, { useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
} from "react-bootstrap";
import Chart from 'chart.js/auto';
import "chartjs-plugin-datalabels";
import "./layout.css";
import { useEffect } from "react";
import { FaMoneyBillWave } from 'react-icons/fa';

const CarLoanCalculator = () => {
  const [loanAmount, setLoanAmount] = useState(100000);
  const [loanDuration, setLoanDuration] = useState(1);
  const [interestRate, setInterestRate] = useState(8);
  const [emi, setEMI] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);

  useEffect(() => {
    calculateEMI(loanAmount, loanDuration,interestRate);
  }, [loanAmount, loanDuration, interestRate]);

  const handleLoanAmountChange = (e) => {
    setLoanAmount(parseInt(e.target.value, 10));
    calculateEMI(parseInt(e.target.value, 10), loanDuration);
  };

  const handleLoanDurationChange = (e) => {
    setLoanDuration(parseInt(e.target.value, 10));
    calculateEMI(loanAmount, parseInt(e.target.value, 10));
  };

  const handleInterestRateChange = (e) => {
    setInterestRate(parseInt(e.target.value, 10));
    calculateEMI(loanAmount, loanDuration, parseInt(e.target.value, 10));
  };

  const calculateEMI = (amount, duration, rate) => {
    const principal = amount;
    const months = duration * 12;
    // const rate = 8;

    const monthlyInterest = rate / 12 / 100;
    const emiAmount =
      (principal * monthlyInterest * Math.pow(1 + monthlyInterest, months)) /
      (Math.pow(1 + monthlyInterest, months) - 1);
    const totalPayment = emiAmount * months;
    const totalInterestPayable = totalPayment - principal;

    setEMI(emiAmount.toFixed(0));
    setTotalInterest(totalInterestPayable.toFixed(0));
    setTotalAmount(totalPayment.toFixed(0));

    if (chart) {
      chart.data.datasets[0].data = [principal, totalInterestPayable];
      chart.update();
    } else {
      const ctx = chartRef.current.getContext('2d');
      const newChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: ['Principal Loan Amount', 'Total Interest Payable'],
          datasets: [
            {
              data: [principal, totalInterestPayable],
              backgroundColor: ['#f70d0245', '#ff281ec7'],
              hoverBackgroundColor: ['#f70d0245', '#ff281ec7'],
            },
          ],
        },
        options: {
          // cutout: '50%',
          circumference: 180,
          rotation: -90,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
      setChart(newChart);
    }
  };
  

  return (
    <Container fluid className="p-4 top_class" style={{background: "lightgrey"}}>
      <Container>
      <Row className="emi-wrapper">
        <Col lg="12" className="carloan-heading">
          {" "}
          Loan Calculator
        </Col>
        <Col sm={6}>
          <Form style={{ padding: "3.5rem" }}>
            <FormGroup>
              <div className="d-flex justify-content-between pb-2">
                <FormLabel>Loan Amount:</FormLabel>
                <div className="slider-value">
                  <span className="slider-value-box">{loanAmount.toLocaleString('en-IN')}</span>
                </div>
              </div>
              <input
                type="range"
                min={50000}
                max={5000000}
                step={10000}
                value={loanAmount}
                className="loan-input"
                onChange={handleLoanAmountChange}
              />
              <div className="slider-value">
                <span>₹ 50,000</span>
                <span>₹ 50,00,000</span>
              </div>

                <FormControl.Feedback type="invalid">
                  Please enter a valid loan amount.
                </FormControl.Feedback>
              </FormGroup>

            <FormGroup>
              <div className="d-flex justify-content-between pb-2">
                <FormLabel>Duration of Loan (Years):</FormLabel>
                <div className="slider-value ">
                  <span className="slider-value-box">{loanDuration} Years</span>
                </div>
              </div>
              <input
                type="range"
                name="years"
                min={1}
                max={5}
                step={1}
                value={loanDuration}
                className="loan-input"
                onChange={handleLoanDurationChange}
              />

                <div className="slider-value">
                  <span>1 Year</span>
                  <span>5 Years</span>
                </div>

              <FormControl.Feedback type="invalid">
                Please enter a valid loan duration.
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup>
    <div className="d-flex justify-content-between pb-2">
      <FormLabel>Interest Rate (% per annum):</FormLabel>
      <div className="slider-value ">
        <span className="slider-value-box">{interestRate}%</span>
      </div>
    </div>
    <input
      type="range"
      min={1}
      max={15} // Adjust the max value based on your desired range
      step={0.5} // Adjust the step value based on your desired precision
      value={interestRate}
      className="loan-input"
      onChange={handleInterestRateChange}
    />

    <div className="slider-value">
      <span>1%</span>
      <span>15%</span>
    </div>

    <FormControl.Feedback type="invalid">
      Please enter a valid interest rate.
    </FormControl.Feedback>
  </FormGroup>

          </Form>
        </Col>
        <Col sm={6} className="d-flex-column loan-right">
          <div className="loan-estimate">
            <h5 className="text-center p-2 pt-5 m-0">EMI</h5>
            <div className="loan-heading">
              <h1 className="m-0 pe-1">₹ {emi}</h1><h6 className="m-0">per month</h6>
            </div>
            <Col className="d-flex justify-content-evenly align-items-center pie-wrapper">
              <Col sm={4}><canvas ref={chartRef}/></Col>
              <Col sm={7}>
                <div className="d-flex justify-content-between">
                  <p className="indi-amount d-flex align-items-center">Principal Loan Amount: </p> <span>₹ {loanAmount}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="indi-interest d-flex align-items-center">Total Interest Payable: </p>
                  <span>₹ {totalInterest}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <p> <FaMoneyBillWave /> Total Amount Payable:</p>
                  <span> ₹ {totalAmount}</span>
                </div>
              </Col>
            </Col>
            <p className="loan-bot-para">
              Disclaimer: Applicable rate of interest can vary subject to credit
              profile. Loan approval is at the sole discretion of the finance
              partner.
            </p>
          </div>
        </Col>
      </Row>
      </Container>
    </Container>
  );
};

export default CarLoanCalculator;
