import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./userProfile.css";
import { ApiGetMethod } from "../js/helper";
import constant from "../js/constant";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import contactimg from "../../image/contactform11.png";


const HelpandSupport = () => {

    const [websiteLinks, setWebsiteLinks] = useState({});
    const formData = new FormData();
    useEffect(() => {
      let data = formData;
      let url = `${constant.baseurl}settings`;
      ApiGetMethod(url, data).then((response) => {
        if (response.error == false) {
          setWebsiteLinks(response.data);
        } else {
        }
      });
    }, []);


    return(
        <>
            <Breadcrumb className="bread_crumb">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Help & Support</Breadcrumb.Item>
            </Breadcrumb>
            <Col lg="12" className="helpSupport-section">
                <Col lg="12" className="supportImage text-center">
                   
                    <img src={contactimg}/>
                </Col>
                <Col lg="12" className="helpSupport-content">
                    <h5 className="text-color text-center bold_text">For any query contact to below number</h5>
                    <h4 className="text-center">{websiteLinks.number}</h4>
                    <h5 className="text-center text-color">OR</h5>
                    <h5 className="text-center text-color bold_text">Reach at our Email</h5>
                    <h4 className="text-center">{websiteLinks.email}</h4>
                </Col>
            </Col>
        </>
    )
};
export default HelpandSupport