import { Container, Col } from "react-bootstrap";
import "./style.css";

const TermsAndCondition = () => {
  return (
    <>
      <Container fluid className="tnc_top background-grey  top_class">
        <Col lg="12" className="privacyhead">
          <p>Terms & Conditions</p>
        </Col>
        <Container>
          <Col lg="12" className="privacy-details">
            <Col lg="11">
              <ol>
                <li className="privacy_heading_wrap">
<span className="privacy_heading">User Eligibility:</span>
<ol>
<li>To participate in vehicle bidding on the Platform, you must meet a minimum age requirement of 18 years.</li>
<li> If you are utilizing the Platform on behalf of a business entity, you affirm and guarantee that you possess the requisite authority to legally bind the said entity to these Terms and Conditions.</li>
</ol>

                </li>
                <li className="privacy_heading_wrap">
                  <span className="privacy_heading">Registration and Account: </span>
                  <ol>
                    <li> In order to engage in the bidding process, it is essential to establish an account on the Platform. It is your responsibility to ensure the confidentiality of your account details, including your password.</li>
                    <li> By using this website, you consent to supplying precise and comprehensive information during the registration procedure, and commit to maintaining the accuracy and currency of your account information.</li>
                  </ol>
                </li>
                <li className="privacy_heading_wrap">
                  <span className="privacy_heading"> Bidding Process:</span>
                  <ol>
                    <li>The Platform offers a bidding mechanism for vehicle transactions, where you have the option to commit to purchasing the vehicle at your specified price if your bid secures the highest position when the auction concludes.</li>
                    <li> Bids hold legal weight on our platform. If you emerge as the successful bidder in an auction, you are under a legal obligation to fulfil the transaction by remitting the winning bid amount.</li>
                  </ol>
                </li>
                <li className="privacy_heading_wrap">
                  <span className="privacy_heading"> Vehicle Listings:</span>
                  <ol>
                    <li>Sellers bear the responsibility of furnishing precise and comprehensive information pertaining to their listed vehicles. This includes details regarding specifications, condition, and the provision of accurate images.</li>
                    <li>At Cars2Cars, we retain the authority to assess, modify, or eliminate any vehicle listing that contravenes our established policies or guidelines.</li>
                  </ol>
                </li>
                <li className="privacy_heading_wrap">
                  <span className="privacy_heading">Payments:</span>
                  <ol>
                    <li>Upon winning an auction, it is mandatory to complete the payment for the winning bid amount within the designated time frame.</li>
                    <li> Payments are securely processed through our designated payment gateway.</li>
                  </ol>
                </li>
                <li className="privacy_heading_wrap">
                  <span className="privacy_heading">Platform Conduct:</span>
                  <ol>
                    <li> You are committed to refraining from participating in any fraudulent, abusive, or unlawful activities while using the Platform.</li>
                    <li> You are strictly prohibited from manipulating bids, participating in bid collusion, or disrupting the bidding process in any manner.</li>
                  </ol>
                </li>
                <li className="privacy_heading_wrap">
                  <span className="privacy_heading">Dispute Resolutions:</span>
                  <ol>
                    <li> Any disputes that may arise between buyers and sellers should be addressed directly between the parties involved. Cars2Cars bears no responsibility for resolving disputes stemming from transactions conducted on the Platform.</li>
                  </ol>
                </li>
                <li className="privacy_heading_wrap">
                  <span className="privacy_heading">Limitation of Liability</span>
                  <ol>
                    <li>Cars2Cars does not provide a guarantee regarding the accuracy, availability, or quality of the vehicles listed on the Platform. Please note that all transactions are exclusively conducted between buyers and sellers.</li>
                    <li> Cars2Cars shall not be held responsible for any direct, indirect, incidental, special, consequential or exemplary damages that may arise for, your use of platform.</li>
                  </ol>
                </li>
                <li className="privacy_heading_wrap">
                  <span className="privacy_heading">Modifications of Terms</span>
                  <ol>
                    <li>Cars2Cars retains the right to amend, update, or revise these Terms at any given moment. Such alterations will come into effect immediately upon being posted on the Platform.</li>
                  </ol>
                </li>
                <li className="privacy_heading_wrap">
                  <span className="privacy_heading">Terminations</span>
                  <ol>
                    <li>Cars2Cars reserves the authority to terminate or suspend your account and access to the Platform in the event of a breach of these Terms or any engagement in unauthorized or inappropriate conduct.</li>
                  </ol>
                </li>
                <li className="privacy_heading_wrap">
                  <span className="privacy_heading">Governing Law</span>
                  <ol>
                    <li>These Terms are subject to and shall be interpreted in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.</li>
                  </ol>
                </li>
              </ol>

            </Col>
          </Col>
        </Container>
      </Container>
    </>
  );
};

export default TermsAndCondition;