import React from "react";
import { loadScript } from "./utils"; // Create a utils file for loading scripts
import { v4 as uuidv4 } from "uuid";
import constant from "../js/constant";
import { ApiPostMethod } from "./helper";
import { useState } from "react";
import Swal from "sweetalert2";

const Payment = ({
  planId,
  planName,
  amount,
  region,
  days,
  planExpire,
  planBenefits,
  carPostId,
}) => {
  const [user_id, setUser_Id] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const carPost_Id = carPostId;

  const displayRazorpay = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert(
        "Razorpay SDK failed to load. Please check your internet connection."
      );
      return;
    }
    const order_id = uuidv4();

    let options = {
      key: "rzp_test_c7KR6i0W3tAoMQ",
      amount: amount * 100,
      user: user_id.name,
      description: planName,

      image: "./profile.png",
      //   order_id: order_id, // Generate the order ID from your backend
      renter_id: user_id.name,
      handler: function (response) {
        const formdata = new FormData();
        formdata.append("transactionId", response.razorpay_payment_id);
        formdata.append("planId", planId);
        formdata.append("planName", planName);
        formdata.append("days", days);
        formdata.append("amount", amount);
        formdata.append("region", region);
        formdata.append("planExpire", planExpire);
        formdata.append("userName", user_id.name);
        formdata.append("mobileNo", user_id.mobile);
        formdata.append("email", user_id.email);
        formdata.append("userId", user_id._id);
        formdata.append("purpose", "subscription");
        formdata.append("type", "inward");
        formdata.append("method", "online");
        formdata.append("planBenefits", planBenefits);
        formdata.append("carPostId", carPost_Id);
        // formdata.append("createdAt", createdAt);
        let data = formdata;
        let url = `${constant.baseurl}payment`;

        ApiPostMethod(url, data).then((response) => {
          if (response.success !== false) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Congratulations! transaction completed successfully...",
            }).then(function () {
              window.location.reload();
            })
            // window.location.reload();
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.message,
            });
          }
        });
      },
      prefill: {
        name: user_id.name,
        email: user_id.email,
        contact: user_id.mobile,
      },
      notes: {
        "Customer Name": user_id.name,
      },
      theme: {
        color: "#f70d02",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <div>
      <button className="col-12 btn-plan" onClick={displayRazorpay}>
        Activate
      </button>
    </div>
  );
};

export default Payment;
